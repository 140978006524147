import React from "react";
import { Link } from "react-router-dom";

const UnlockedProfiles = () => {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="relative flex-center mb-3">
            <img
              src="./images/building1.png"
              alt=""
              className="unlocked-profile-img w-100 img-fluid"
            />
            <p className="hire-status">Shortlisted</p>
            <div className="profile-match horizontal-center">
              <img src="./images/match-badge.png" className=" mr-1" alt="" />
              <span className="match-score">99% Match</span>
            </div>
            <div className="row flex-center px-2 posted-by-info-box">
              <div className="col-2 px-0">
                <img src="./images/user1.png" />
              </div>
              <div className="col-10 pl-2">
                <p className="job-by-name">Juzzi</p>
                <p className="job-by-company">Zefort</p>
              </div>
            </div>
          </div>
          <h2 className="card-heding mt-4">Plumbers Needed</h2>
          <p className="designation my-2">ABC Company</p>
          <div className="unlocked-date">
            <p className="unlocked-time-jobseeker">Unlocked on 21/03/2021, 01:00 pm</p>
          </div>

          <div className="row mt-3">
            <div
              className="col-6 px-0"
              style={{ borderRight: "1px solid #0000004d" }}
            >
              <p className="profile-info text-center">3 Years</p>
              <p className="card-subHeading text-center mt-3">
                Total Experience
              </p>
            </div>
            <div className="col-6 px-0">
              <p className="profile-info text-center">2KM Away</p>
              <p className="card-subHeading text-center mt-3">
                Current Location
              </p>
            </div>
            <div className="col-6 px-0"></div>
            <div className="col-6 px-0"></div>
          </div>

          <button className="jobseeker-btn mt-3">Chat Now</button>
        </div>
      </div>


      <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="relative flex-center mb-3">
            <img
              src="./images/building1.png"
              alt=""
              className="unlocked-profile-img w-100 img-fluid"
            />
            <p className="hire-status">Shortlisted</p>
            <div className="profile-match horizontal-center">
              <img src="./images/match-badge.png" className=" mr-1" alt="" />
              <span className="match-score">99% Match</span>
            </div>
            <div className="row flex-center px-2 posted-by-info-box">
              <div className="col-2 px-0">
                <img src="./images/user1.png" />
              </div>
              <div className="col-10 pl-2">
                <p className="job-by-name">Juzzi</p>
                <p className="job-by-company">Zefort</p>
              </div>
            </div>
          </div>
          <h2 className="card-heding mt-4">Plumbers Needed</h2>
          <p className="designation my-2">ABC Company</p>
          <div className="unlocked-date">
            <p className="unlocked-time-jobseeker">Unlocked on 21/03/2021, 01:00 pm</p>
          </div>

          <div className="row mt-3">
            <div
              className="col-6 px-0"
              style={{ borderRight: "1px solid #0000004d" }}
            >
              <p className="profile-info text-center">3 Years</p>
              <p className="card-subHeading text-center mt-3">
                Total Experience
              </p>
            </div>
            <div className="col-6 px-0">
              <p className="profile-info text-center">2KM Away</p>
              <p className="card-subHeading text-center mt-3">
                Current Location
              </p>
            </div>
            <div className="col-6 px-0"></div>
            <div className="col-6 px-0"></div>
          </div>

          <button className="jobseeker-btn mt-3">Chat Now</button>
        </div>
      </div>


      <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="relative flex-center mb-3">
            <img
              src="./images/building1.png"
              alt=""
              className="unlocked-profile-img w-100 img-fluid"
            />
            <p className="hire-status">Shortlisted</p>
            <div className="profile-match horizontal-center">
              <img src="./images/match-badge.png" className=" mr-1" alt="" />
              <span className="match-score">99% Match</span>
            </div>
            <div className="row flex-center px-2 posted-by-info-box">
              <div className="col-2 px-0">
                <img src="./images/user1.png" />
              </div>
              <div className="col-10 pl-2">
                <p className="job-by-name">Juzzi</p>
                <p className="job-by-company">Zefort</p>
              </div>
            </div>
          </div>
          <h2 className="card-heding mt-4">Plumbers Needed</h2>
          <p className="designation my-2">ABC Company</p>
          <div className="unlocked-date">
            <p className="unlocked-time-jobseeker">Unlocked on 21/03/2021, 01:00 pm</p>
          </div>

          <div className="row mt-3">
            <div
              className="col-6 px-0"
              style={{ borderRight: "1px solid #0000004d" }}
            >
              <p className="profile-info text-center">3 Years</p>
              <p className="card-subHeading text-center mt-3">
                Total Experience
              </p>
            </div>
            <div className="col-6 px-0">
              <p className="profile-info text-center">2KM Away</p>
              <p className="card-subHeading text-center mt-3">
                Current Location
              </p>
            </div>
            <div className="col-6 px-0"></div>
            <div className="col-6 px-0"></div>
          </div>

          <button className="jobseeker-btn mt-3">Chat Now</button>
        </div>
      </div>


      <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="relative flex-center mb-3">
            <img
              src="./images/building1.png"
              alt=""
              className="unlocked-profile-img w-100 img-fluid"
            />
            <p className="hire-status">Shortlisted</p>
            <div className="profile-match horizontal-center">
              <img src="./images/match-badge.png" className=" mr-1" alt="" />
              <span className="match-score">99% Match</span>
            </div>
            <div className="row flex-center px-2 posted-by-info-box">
              <div className="col-2 px-0">
                <img src="./images/user1.png" />
              </div>
              <div className="col-10 pl-2">
                <p className="job-by-name">Juzzi</p>
                <p className="job-by-company">Zefort</p>
              </div>
            </div>
          </div>
          <h2 className="card-heding mt-4">Plumbers Needed</h2>
          <p className="designation my-2">ABC Company</p>
          <div className="unlocked-date">
            <p className="unlocked-time-jobseeker">Unlocked on 21/03/2021, 01:00 pm</p>
          </div>

          <div className="row mt-3">
            <div
              className="col-6 px-0"
              style={{ borderRight: "1px solid #0000004d" }}
            >
              <p className="profile-info text-center">3 Years</p>
              <p className="card-subHeading text-center mt-3">
                Total Experience
              </p>
            </div>
            <div className="col-6 px-0">
              <p className="profile-info text-center">2KM Away</p>
              <p className="card-subHeading text-center mt-3">
                Current Location
              </p>
            </div>
            <div className="col-6 px-0"></div>
            <div className="col-6 px-0"></div>
          </div>

          <button className="jobseeker-btn mt-3">Chat Now</button>
        </div>
      </div>
      
    </>
  );
};

export default UnlockedProfiles;
