import React from "react";
import { Link } from "react-router-dom";
import ApplicantsAvailability from "./ApplicantsAvailability";

const ApplicantsDetails = (props) => {
  return (
    <>
      <div className="blur-screen-full-area flex-center flex-column">
        <div className="details">
          <div
            className=" mx-auto"
            style={{
              backgroundImage: "url('./images/Rectangle-scenery.png')",
              width: "33%",
              backgroundSize: "cover",
              borderRadius: "14px",
              minWidth: "320px",
            }}
          >
            <div className="applicants-details-container">
              <div className="row">
                <div className="col-10 pl-0">
                  <div className="row">
                    <div className="col-3 px-0 mb-3 flex-center relative">
                      <img src="./images/msg1.png" className="" alt="" />
                      <p className="premium">Premium</p>
                    </div>
                    <div className="col-9 px-0">
                      <p className="candidate-name">Ramesh D</p>
                      <p className="designation text-bold text-white">
                        Plumber
                        <span className="verified">
                          <img src="./images/verified.png" alt="" /> Verified
                        </span>
                      </p>
                    </div>
                  </div>

                  <p className="heading h5 mt-2 text-white">
                    Plumber with 5+ Years of Experience working with Builders &
                    Individuals
                  </p>

                  <p className="sub-heading text-white font-12 mt-2">About</p>
                  <p className="text-white text">
                    We Lorem ipsum dolor sit amet, consectadipiscing elit, sed
                    do eiusmsit at,consectetur adipiscing eliem Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit, sed do
                    eiusm.eiusmsit at,consectetur adipiscing eliem Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit
                  </p>
                  <p className="sub-heading text-white font-12 mt-2">
                    Experience
                  </p>
                  <p className="text-white text">
                    5+ Years of Experience required
                  </p>
                  <p className="sub-heading text-white font-12 mt-2">
                    Employment Type
                  </p>
                  <p className="text-white text">
                    Full Time - Part Time{" "}
                    <span className="text-warning text-bold">
                      (Available 20 Hrs/week)
                    </span>
                  </p>
                  <p className="sub-heading text-white font-12 mt-2">
                    Expected Salary
                  </p>
                  <p className="text-white text">€ 200/ pm - € 300/ pm</p>

                  <p className="sub-heading text-white font-12 mt-2">Skills</p>
                  <div className="d-block">
                    <span className="transparent-border-btn">
                      Bootstrap 4/ Bootsrap 5
                    </span>
                    <span className="transparent-border-btn">CSS</span>
                    {/* <span className="transparent-border-btn">ReactJs</span>
                <span className="transparent-border-btn">JavaScrpt</span> */}
                  </div>
                  <p className="sub-heading text-white font-12 mt-2">
                    Education
                  </p>
                  <div>
                    <span className="transparent-border-btn">B.Sc Science</span>
                    <span className="transparent-border-btn">B.Sc Science</span>
                  </div>

                  <p className="sub-heading text-white font-12 mt-2">
                    Passout Year
                  </p>
                  <div>
                    <span className="transparent-border-btn">2017</span>
                  </div>
                  <p className="sub-heading text-white font-12 mt-2">
                    Licenses
                  </p>
                  <div>
                    <span className="transparent-border-btn">
                      License Name 1
                    </span>
                    <span className="transparent-border-btn">
                      License Name 2
                    </span>
                  </div>

                  <div className="mt-4">
                    <span className="transparent-border-btn px-3">
                      {" "}
                      <img
                        src="./images/lock-white.png"
                        className="mr-2"
                        alt=""
                      />{" "}
                      Contact Details Locked
                    </span>
                  </div>

                  <button className="theme-btn mt-3">See Full Details </button>
                </div>
                <div className="col-2 px-0">
                  <div className=" flex-column sticky-top">
                    <div className="white-circle flex-center flex-column">
                      <p className="font-12">99%</p>
                      <p className="font-8">Match</p>
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/bookmark.png" alt="" />
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/send-job.png" alt="" />
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/share-icon.png" alt="" />
                    </div>

                    <div className="blue-circle flex-center">
                      <img src="./images/unlcoked-profile-white.png" alt="" />
                    </div>
                    <div
                      className="red-circle flex-center"
                      onClick={props.closePopup}
                    >
                      <img src="./images/cross.png" alt="" />
                    </div>
                    <Link to="/Calender" className="link">
                      <div className="yellow-circle flex-center flex-column">
                        <p className="font-12">Hire</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="applicants-distance">
                <p className="distance-text">
                  {" "}
                  <img
                    src="./images/location.png"
                    className=" img-size16"
                    alt=""
                  />{" "}
                  3Km Away{" "}
                </p>
              </div>
            </div>
          </div>

          <ApplicantsAvailability />
        </div>
      </div>
    </>
  );
};

export default ApplicantsDetails;
