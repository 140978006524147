import React from "react";

const HireBox = (props) => {
  return (
    <>
      <div className="col-lg-6 col-md-6 c0l-sm-12 col-12">
        <div className="recommended-profile pb-2" onClick={props.closePopup}>
          <div className="row">
            <div className="col-4 px-0 relative flex-center">
              <img
                src="./images/profile1.png"
                alt=""
                className="w-100"
              />
              <div className="view-profile horizontal-center">
                <span className="view-profile-link">View Full Profile</span>
              </div>
            </div>
            <div className="col-8 pr-0 mt-1 flex-column d-flex justify-content-center">
              <h2 className="card-heding relative">
                Bucky Rodgers
                <img
                  src="./images/profile-download.png"
                  style={{ height: "unset", width: "unset" }}
                  alt=""
                  className="addprofile"
                />
              </h2>
              <p className="hired-designation">Plumber</p>
              <div className="row mt-1">
                <div className="col-6 px-0">
                  <p className="card-subHeading">Hired Date</p>
                </div>
                <div className="col-6 px-0">
                  <p className="profile-info">03 Feb, 2021 </p>
                </div>
                <div className="col-6 px-0">
                  <p className="card-subHeading">Job Duration</p>
                </div>
                <div className="col-6 px-0">
                  <p className="profile-info">03 Feb, 2021 - 30 Feb, 2021</p>
                </div>
                <div className="col-6 px-0">
                  <p className="card-subHeading">Hours Workeds</p>
                </div>
                <div className="col-6 px-0">
                  <p className="profile-info"> 40 Hours </p>
                </div>
              </div>
              <hr className="blue-line-bottom" />
              <hr className="blue-line" />
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default HireBox;
