import React from 'react'

const PassoutYear = (props) => {
    return (
        <div className="filter-collapse" style={{ width: props.widthhh }}>
            <div className="d-flex space-between">
                <p className="toggle-text">Less then 2015  </p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2016 </p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2017</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2018</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2019</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2020</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2021</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

        </div>
    );
}

export default PassoutYear