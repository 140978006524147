import React, { useState } from 'react'
import "../styles/styles.css"
import { Link, useNavigate } from 'react-router-dom';
import { registrationUrl } from '../Service/service';
import { postData } from '../Service/ApiMethod';
// import axios from "axios";



const Registration = () => {
    const navigate = useNavigate();

    const navigateToSuccess = () => {
        navigate("/home");
    };

    const [password, setPassword] = useState();
    const [username, setUserName] = useState();
    const [phone, setPhone] = useState();
    const [error, setError] = useState();

    // by VTT

    const signUp = async () => {


        // if (username.trim() === '') {
        //     setError('Please enter your name');
        //     return;
        // }

        // if (phone.trim() === '') {
        //     setError('Please enter your Number yrr');
        //     return;
        // } else if (password.trim() === '' || password.trim().length < 8) {
        //     setError('Minimum 8 character lenght required');
        //     return;
        // }

        let objItem = {
            username: username,
            phone: phone,
            password: password,
            user_types: "recruiter"
        };


        console.log("Static", objItem);
        await postData(objItem, registrationUrl)
            .then(response => response.json())
            .then(data => {
                if (data.message !== "") {
                    console.log("from API ", data.message, +" "+ data?.data?.user?.username,);
                    alert(data?.message + " Welcome " + data?.data?.user?.username);
                } else if (data?.errors?.phone !== "") {
                    alert(data?.errors?.phone);
                }
                try {
                    localStorage.setItem(
                        "access token", data?.data?.access,
                        'UserName', data?.data?.user?.username,
                        'login_id', data?.data?.user?.id
                    );
                    
                    navigateToSuccess();
                } catch (err) {
                    console.log('error hgappended', err)
                    setError(err.message, 'email already exists');
                    alert("Eoorrror", err)
                }
            })
        // .catch(error => {
        //     console.log('show the error', error);
        // });
    };











    // let item = {email, phone, password};


    // await postData(item, registrationUrl)
    // .then(response => response.json())
    // .then(data => {
    //     if(data.statusCode === undefined){
    //         navigate("/rahul");
    //         localStorage.setItem(
    //             "user"
    //         )
    //     }
    // })


    // let item = { userName, password, phone };
    // console.log(item);
    // const register = async () => {
    //     console.log("Clicked");

    //     let item = { username, password, phone }
    //     console.log(item);

    //     let ress = await fetch(registrationUrl, {
    //         mode: 'no-cors',
    //         method: "POST",
    //         body: JSON.stringify(item),
    //         headers: {
    //             // "Accept": "*/*",
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             "Access-Control-Allow-Origin": "http://localhost:3000",
    //         }
    //     });

    //     ress = await ress.json();
    //     console.log("Result", ress);
    //     // navigateToSuccess();

    // }
    return (
        <>
            {console.log(error)}
            <div className="login-section">
                <div className="image-layer image-layer-2" style={{ backgroundImage: "url(images/log-bg.png)" }}></div>
                <div className="outer-box">
                    {/* <!-- Login Form --> */}
                    <div className="login-form default-form">
                        <div className="form-inner">
                            <h3>Create a Free Catzapp Account</h3>

                            {/* <!--Login Form--> */}
                            <>
                                <div className="form-group">
                                    {/* <!-- <div className="btn-box row">
                                        <div className="col-lg-6 col-md-12">
                                            <a href="#" className="theme-btn btn-style-one"><i className="la la-user"></i> Employee </a>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <a href="#" className="theme-btn btn-style-three"><i className="la la-briefcase"></i> Employer </a>
                                        </div>
                                    </div>--> */}
                                </div>

                                <div className="form-group">
                                    <label>User Name</label>
                                    <input onChange={(e) => { setUserName(e.target.value) }} value={username} type="text" name="userName" placeholder="User Name" required />
                                </div>

                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input onChange={(e) => { setPhone(e.target.value) }} value={phone} id="number-field" type="text" name="phone" placeholder="Phone Number" />
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <input onChange={(e) => { setPassword(e.target.value) }} value={password} id="password-field" type="password" name="password" placeholder="Password" />
                                </div>

                                <div className="form-group">
                                    <button className="theme-btn btn-style-one py-2" type="" onClick={signUp} name="Register">Register</button>
                                </div>
                            </>

                            <div className="bottom-box pt-0">
                                <div className="divider mb-3"><span>or</span></div>
                                <div className="btn-box row">
                                    <div className="col-lg-6 col-md-12">
                                        <a href="/" className="theme-btn social-btn-two facebook-btn"><i className="fab fa-facebook-f"></i> Log In via Facebook</a>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <a href="/" className="theme-btn social-btn-two google-btn"><i className="fab fa-google"></i> Log In via Gmail</a>
                                    </div>
                                </div>
                                <Link to="/" className='link text-underline'>
                                    <span>Already have an account</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <!--End Login Form --> */}
                </div>
            </div>
        </>
    )
}

export default Registration