import React, { useState } from "react";
import Availbility from "./Availbility";
import ProfessionalInfo from "./ProfessionalInfo";

const Profile = () => {
  const [profileStep, setProfileStep]= useState(1);
  return (
    <>
      <div className=" flex-center">
        <div className="jobseeker-profile my-3">
          <div className="my-3 flex-center flex-column">
            <div className="profile-circle relative">
              <img src="./images/blankProfile.png" alt="" />
              <div className="profileSelect">
                <img src="./images/pencilBlack.png" alt="" className="" />
              </div>
            </div>
            <p className="profile-name">Upload image</p>
          </div>
          {profileStep === 1 ? (
            <>
              <h5 className="mb-3">Personal Information</h5>
              <div className="Employment-Details mt-3">
                <input
                  type="text"
                  placeholder=" First Name *"
                  className="input-text w-100 mt-2"
                />

                <input
                  type="text"
                  placeholder="Last Name"
                  className="input-text w-100 mt-2"
                />
                <input
                  type="email"
                  placeholder="Email "
                  className="input-text w-100 mt-2"
                />
                <input
                  type="text"
                  placeholder="Mobile Number"
                  className="input-text w-100 mt-2"
                />
                <input
                  type="text"
                  placeholder="Profile Title"
                  className="input-text w-100 mt-2"
                />
                <button className="mt-3 btn-block jobseeker-btn" onClick={()=> {setProfileStep(2)}}>Next</button>
              </div>
            </>
          ) : null}
          {profileStep === 2 ? <ProfessionalInfo nextStep={()=>{setProfileStep(3)}} /> : null}

          {profileStep === 3 ? <Availbility/> : null}
          
        </div>
      </div>
    </>
  );
};

export default Profile;
