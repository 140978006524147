import React, { useState, useEffect } from "react";
import Header from "../../common/HeaderAfterLogin";
import Chat from "./Chat";
import MessageBox from "./MessageBox";

function getWindowDimensions() {
  const { innerWidth: width, /*innerHeight: height*/ } = window;
  return {
    width,
    // height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Messages = () => {
  const[chatBoxOpen, setChatBoxOpen] = useState(false);

  const { /*height,*/ width } = useWindowDimensions();

  return (
    <>
      <Header />
      <div className="wrapped-content-area">
        {!chatBoxOpen ?
        <div className="container-fluid">
          <hr className="mb-0 mt-1" />
          <div className="hashtags-keys">
            <p className="filters rounded">Filters:- </p>
            <button className="theme-btn ">All</button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Unread
            </button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              First Round
            </button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Second Round
            </button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Rejected
            </button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Selected
            </button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Shortlisted
            </button>
            <button
              className="theme-btn deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Freshers
            </button>
          </div>
          <hr className="mb-0 mt-2" />
        </div> : null }
        {/* Chat And Messages */}
        <div className="messages-chat">
          <div className="row">
            {!chatBoxOpen || (width > 767) ?
            <div
              className={width > 767 ? "col-4 px-0 message-box-container message-area" : "col-12 px-0 message-box-container message-area"}
              style={{ background: "#fff" }}
            >
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)} />
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)}/>
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)}/>
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)} />
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)}/>
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)}/>
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)}/>
              <MessageBox openChatWindow={()=> setChatBoxOpen(true)}/>
            </div> : null }
            {chatBoxOpen || (width > 767) ?
            <div className={width > 767 ? "col-8 chat-area" : "col-12 chat-area"}>
              {chatBoxOpen ? 
              <Chat onClose={() => setChatBoxOpen(false)} /> : null }
            </div> : null } 
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Messages;
