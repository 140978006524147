import React from "react";

const ForgetPWD = (props) => {
  return (
    <div>
      <div className="" style={{textAlign:'center'}}>
        <img src="./images/login.png" alt="" className="login-img" />
        <p className="text text-center">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium
          soluta asperiores tempore incidunt, nisi impedit?
        </p>
        <div>
          <div className="input-container my-2">
            <input
              className="input-text w-100 pl-5"
              placeholder="Enter Mobile Number"
              type="text"
              name=""
              id=""
            />
            <img src="./images/call.png" alt="" className="left-img" />
          </div>
        </div>

        <button className="theme-btn" style={{width:"100%"}} onClick={() => props.setActiveSection(1)}>Get Password</button>
      </div>
    </div>
  );
};

export default ForgetPWD;
