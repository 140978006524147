import React from 'react'

const EditProfile = (props) => {
    return (
        <div className='blur-screen-full-area flex-center'>
            <div className="edit-profile">
                <h2 class="heading mb-3 text-center">Update Profile</h2>
                <div className='edit-profile-fields'>
                    <input className='edit-profile-input mb-2 w-100' type="text" placeholder="First Name" />
                    <input className='edit-profile-input mb-2 w-100' type="text" placeholder="Last Name" />

                    <input className='edit-profile-input mb-2 w-100' type="text" placeholder="Enter Number" />

                    <input className='edit-profile-input mb-2 w-100' type="email" placeholder="Enter Email" />

                    <input className='edit-profile-input mb-2 w-100' type="text" placeholder="Enter Company Name" />

                    <input className='edit-profile-input mb-2 w-100' type="url" placeholder="Enter Website link" />


                    <textarea
                        name="address"
                        id="address"
                        cols=""
                        className="edit-profile-input w-100 mb-2"
                        rows="4"
                        placeholder="Company Address"
                    ></textarea>

                    <textarea
                        name=""
                        id="bio"
                        cols=""
                        className="edit-profile-input w-100 mb-2"
                        rows="4"
                        placeholder="Company Bio"
                    ></textarea>

                    <input className='edit-profile-input mb-2 w-100' type="File" />

                    <button className='theme-btn'onClick={props.submit}>Update</button>

                </div>
            </div>

        </div>
    )
}

export default EditProfile