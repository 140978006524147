import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
const Otp = () => {

    const navigate = useNavigate();

    const [verifyButtonDisable, setVerifyButtonDisable] = useState(true);
    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const[sendOtp, setSendOtp]= useState(false);

  return (
    <>
      <img src="./images/jobseeker-otp.png" alt="" className="m-auto" />
          <p className="text text-center mt-5">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Accusantium soluta asperiores tempore incidunt, nisi impedit?
          </p>
          <div className="">

              {sendOtp?
              
              <div className="mobile_four_digit_verification_code flex-center justify-content-between my-3">
                    <input
                        className="inpur_tupe_number_for_verification_one_time_one text-center"
                        type="text"
                        id="otp1"
                        tabIndex={1}
                        name="ssn-1"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 1);
                            setOtp1(e.target.value);
                        }}
                    />
                    <input
                        className="inpur_tupe_number_for_verification_one_time_two text-center"
                        type="text"
                        id="otp2"
                        tabIndex={2}
                        name="ssn-2"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 1);
                            setOtp2(e.target.value);
                        }}
                    />
                    <input
                        className="inpur_tupe_number_for_verification_one_time_three text-center"
                        type="text"
                        id="otp3"
                        tabIndex={3}
                        name="ssn-3"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 1);
                            setOtp3(e.target.value);
                        }}
                    />
                    <input
                        className="inpur_tupe_number_for_verification_one_time_four text-center"
                        type="text"
                        id="otp4"
                        tabIndex={4}
                        name="ssn-4"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 1);
                            setOtp4(e.target.value);
                            setVerifyButtonDisable(false);
                        }}
                    />
                    <input
                        className="inpur_tupe_number_for_verification_one_time_three text-center"
                        type="text"
                        id="otp3"
                        tabIndex={3}
                        name="ssn-3"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 1);
                            setOtp3(e.target.value);
                        }}
                    />
                    <input
                        className="inpur_tupe_number_for_verification_one_time_four text-center"
                        type="text"
                        id="otp4"
                        tabIndex={4}
                        name="ssn-4"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 1);
                            setOtp4(e.target.value);
                            setVerifyButtonDisable(false);
                        }}
                    />
                </div>
              
              :
              
              <div className="input-container my-2">
              <input
                className="input-text w-100 pl-5"
                placeholder="Enter Mobile Number"
                type="text"
                name=""
                id=""
              />
              <img src="./images/call.png" alt="" className="left-img" />
            </div>
              }
            
          </div>

          {sendOtp? <button className="jobseeker-btn" onClick={()=>navigate('/jobseeker-home')}>Submit OTP</button>  : 
          <button className="jobseeker-btn my-3"  onClick={()=>{setSendOtp(true)}}>Send OTP</button>  
          }

          

          {sendOtp?<p className="text-center text my-3">Did not Recieve Code yet?  <button className="resend">Resend OTP</button></p>:null }

          
    </>
  )
}

export default Otp