import React from "react";
import rahul from "./ProfileData";

const UnlockedProfiles = () => {
  return (
    <>
      {rahul.map((item, index) => {
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0" key={index}>
            <div className="unlocked-profile w-100">
              <div className="relative flex-center mb-3">
                <img
                  src={item.profile_pic}
                  alt=""
                  className="unlocked-profile-img img-fluid"
                />
                <img
                  src="./images/save-profile.png"
                  alt=""
                  className="save-profile"
                />
                <p className="hire-status">Shortlisted</p>
                <div className="profile-match horizontal-center">
                  <img
                    src="./images/match-badge.png"
                    className=" mr-1"
                    alt=""
                  />
                  <span className="match-score">99% Match</span>
                </div>
              </div>
              <h2 className="card-heding mt-4">{item.candidate_name}</h2>
              <p className="designation my-2">{item.designation}</p>
              <div className="unlocked-status">
                <p className="unlocked-time">
                  Unlocked on 21/03/2021, 01:00 pm
                </p>
              </div>

              <div className="row mt-3">
                <div
                  className="col-6 px-0"
                  style={{ borderRight: "1px solid #0000004d" }}
                >
                  <p className="profile-info text-center">{item.total_experience} </p>
                  <p className="card-subHeading text-center mt-3">
                    Total Experience
                  </p>
                </div>
                <div className="col-6 px-0">
                  <p className="profile-info text-center">2KM Away</p>
                  <p className="card-subHeading text-center mt-3">
                    Current Location
                  </p>
                </div>
                <div className="col-6 px-0"></div>
                <div className="col-6 px-0"></div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default UnlockedProfiles;
