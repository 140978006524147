import React from "react";
import { Link } from "react-router-dom";

const JobseekerRecommendedJobs = () => {
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Link to="/jobseeker-myJob" className=" link ">
          <div className="applied-box">
            <div className="row">
              <div className="col-4 px-0 flex-center info-logo flex-column justify-content-start">
                <div className="row flex-center px-2 my-2">
                  <div className="col-2 px-0">
                    <img src="./images/user1.png" />
                  </div>
                  <div className="col-10 pl-2">
                    <p
                      className="match-score"
                      style={{ fontSize: "8px", lineHeight: "10px" }}
                    >
                      Juzzi
                    </p>
                    <p
                      className="match-score text-bold"
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        lineHeight: "10px",
                      }}
                    >
                      Zefort
                    </p>
                  </div>
                </div>
                <img
                  src="./images/plumber.png"
                  alt=""
                  className="vaccancy-pic"
                />
                <div className="horizontal-center btn-bottom-profile-logo px-2">
                  <button className="jobseeker-btn">Apply Now</button>
                </div>
              </div>
              <div className="col-8 pr-0 mt-1">
                <div className="space-between d-flex">
                  <div>
                    <h2 className="card-heding relative">Plumber</h2>
                    <p className="job-requirement ">
                      Required Plumbers Immediately
                    </p>
                  </div>

                  <p className="">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="row mt-1">
                  <div className="col-5 px-0">
                    <p className="card-subHeading">Total Experience</p>
                    <p className="card-subHeading">Work Location</p>
                    <p className="card-subHeading">Package</p>
                    <p className="card-subHeading">Employment Type</p>
                  </div>
                  <div className="col-7 px-0">
                    <p className="profile-info">1.5 Years </p>
                    <p className="profile-info">Finland</p>
                    <p className="profile-info"> $700-$1000/m </p>
                    <p className="profile-info"> Part Time/Full Time </p>
                  </div>
                </div>
              </div>

              <div className="space-between mt-3 d-flex w-100">
                <p className="fade-text text-right">Current Location: 2km Away</p>
                <p className="fade-text text-right" style={{fontSize: "8px"}}>Posted 1 day ago</p>
              </div>
            </div>
          </div>
        </Link>
      </div>


      <div className="col-lg-6 col-md-6 col-sm-12">
        <Link to="/jobseeker-myJob" className=" link ">
          <div className="applied-box">
            <div className="row">
              <div className="col-4 px-0 flex-center info-logo flex-column justify-content-start">
                <div className="row flex-center px-2 my-2">
                  <div className="col-2 px-0">
                    <img src="./images/user1.png" />
                  </div>
                  <div className="col-10 pl-2">
                    <p
                      className="match-score"
                      style={{ fontSize: "8px", lineHeight: "10px" }}
                    >
                      Juzzi
                    </p>
                    <p
                      className="match-score text-bold"
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        lineHeight: "10px",
                      }}
                    >
                      Zefort
                    </p>
                  </div>
                </div>
                <img
                  src="./images/plumber.png"
                  alt=""
                  className="vaccancy-pic"
                />
                <div className="horizontal-center btn-bottom-profile-logo px-2">
                  <button className="jobseeker-btn">Apply Now</button>
                </div>
              </div>
              <div className="col-8 pr-0 mt-1">
                <div className="space-between d-flex">
                  <div>
                    <h2 className="card-heding relative">Plumber</h2>
                    <p className="job-requirement ">
                      Required Plumbers Immediately
                    </p>
                  </div>

                  <p className="">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="row mt-1">
                  <div className="col-5 px-0">
                    <p className="card-subHeading">Total Experience</p>
                    <p className="card-subHeading">Work Location</p>
                    <p className="card-subHeading">Package</p>
                    <p className="card-subHeading">Employment Type</p>
                  </div>
                  <div className="col-7 px-0">
                    <p className="profile-info">1.5 Years </p>
                    <p className="profile-info">Finland</p>
                    <p className="profile-info"> $700-$1000/m </p>
                    <p className="profile-info"> Part Time/Full Time </p>
                  </div>
                </div>
              </div>

              <div className="space-between mt-3 d-flex w-100">
                <p className="fade-text text-right">Current Location: 2km Away</p>
                <p className="fade-text text-right" style={{fontSize: "8px"}}>Posted 1 day ago</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default JobseekerRecommendedJobs;
