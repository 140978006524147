import React, { useState } from "react";
import CreditTopup from "../subcription/CreditTopup";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const CreditCoins = (props) => {
  const navigate = useNavigate();

  const navigateToSuccess = (e) => {
    navigate("/payment-success");
    e.preventDefault();
  };

  const [creditTopup, setCreditTopup] = useState(false);
  const [subcriptionDetail, setSubcriptionDetail] = useState(false);
  return (
    <div className="credit-coins-container">
      <div className="credit-coins">
        <div className="space-between d-flex mb-2">
          <h2 className="popup-heading">
            Credit balance
            <img src="./images/credit-coins.png" className="ml-2" alt="" />
          </h2>
          <button className="view-all btn-none" onClick={props.crossClose}>Close</button>
        </div>
        <div className="space-between d-flex">
          <p className="text">Available Credits</p>
          <p className="text text-bold">200</p>
        </div>
        <div className="space-between d-flex mb-2">
          <p className="text">Used Credits</p>
          <p className="text text-bold">300</p>
        </div>
        <hr className="line m-0 mb-2" />

        <div
          className="space-between d-flex pb-2"
          style={{ borderBottom: "1px dashed grey" }}
        >
          <p className="text">Total Credits</p>
          <p className="text text-bold">500</p>
        </div>
      </div>

      <div className="subcription-details">
        <h2 className="popup-heading my-2">Subscription Details</h2>

        <div className="space-between d-flex " style={{}}>
          <div className="div mb-2">
            <h2 className="text">
              Plan ABC
              <button
                className="btn-none"
                onClick={() => {
                  setSubcriptionDetail(!subcriptionDetail);
                }}
              >
                <img src="./images/toggle-down.png" className="ml-2" alt="" />
              </button>
            </h2>
          </div>
          <div className="days-left">03 days Left</div>
        </div>

        {subcriptionDetail ? (
          <>
            <div className="my-3">
              <div className="space-between d-flex " style={{}}>
                <div className="div mb-2">
                  <h2 className="text">Purchased Date</h2>
                </div>
                <div className="text text-right text-black-50">22 Mar 2021</div>
              </div>
              <div className="space-between d-flex " style={{}}>
                <div className="div mb-2">
                  <h2 className="text">Validity</h2>
                </div>
                <div className="text text-right text-black-50">30 Days</div>
              </div>
              <div className="space-between d-flex " style={{}}>
                <div className="div mb-2">
                  <h2 className="text">Amount</h2>
                </div>
                <div className="text text-right text-black-50">$ 3,000.00</div>
              </div>
              <div className="space-between d-flex " style={{}}>
                <div className="div mb-2">
                  <h2 className="text">Payment Mode</h2>
                </div>
                <div className="text text-right text-black-50">Credit Card</div>
              </div>
            </div>
          </>
        ) : null}

        <p className="text" style={{ fontSize: "10px", marginTop: "-7px" }}>
          (30 days Validity)
        </p>
        <div
          className="d-flex justify-content-around my-3 pb-3"
          style={{ borderBottom: "1px dashed grey" }}
        >
          <Link to="/subcription" className="theme-btn-outline m-0 me-2">Upgrade Plan</Link>
          <button
            className="theme-btn ms-2 "
            onClick={() => {
              setCreditTopup(!creditTopup);
            }}
          >
            Credit Top-Up
          </button>
        </div>
        {creditTopup ? (
          <CreditTopup
            closePopup={(e) => {
              setCreditTopup(!creditTopup);
              navigateToSuccess(e);
            }}
          />
        ) : null}

        {/* <hr className="line m-0" /> */}

        <div className="space-between d-flex my-3 horizontal-center">
          <p className="text text-bold">Credit History</p>
          <img src="./images/filter-icon.png" className="h-100" alt="" />
        </div>

        <p className="credit-date my-2">Today</p>

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>
        <hr className="line m-0" />

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>

        <p className="credit-date my-2">Yesterday</p>

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>
        <hr className="line m-0" />

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>
        <hr className="line m-0" />

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>
        <hr className="line m-0" />

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>
        <hr className="line m-0" />

        <div className="row msg-notification horizontal-center p-2">
          <div className="col-2 px-0">
            <img src="./images/msg1.png" className="mr-0" alt="" />
          </div>
          <div className="col-7 pr-0">
            <div className="div">
              <p className="text">Jobseeker name</p>
              <p className="profile">Technician</p>
            </div>

            {/* <button className="view-notification">View Message</button> */}
          </div>
          <div
            className="col-3 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className=" horizontal-center">
              <p className="text">
                -2{" "}
                <img src="./images/credit-coins.png " className="ml-1" alt="" />
              </p>
              <img
                src="./images/go-arrow.png"
                className="ms-4 h-100 ml-4"
                alt=""
              />
            </p>
          </div>
        </div>
        <hr className="line m-0" />
      </div>
    </div>
  );
};

export default CreditCoins;
