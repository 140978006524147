import React from "react";
import BackBar from "../home/BackBar";

const Subuser = () => {
  return (
    <>
      <BackBar title="Subusers" />
      {/* <div className="space-between d-flex my-3 horizontal-center">
        <p className="text text-bold heading">Subusers</p>
      </div> */}

      <hr className="line m-0 mb-3" />
      <div className="mb-3">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 pl-0">
            <div className="row sub-user-box horizontal-center pb-2">
              <div className="col-2 px-0">
                <img src="./images/msg1.png" className="mr-0" alt="" />
              </div>
              <div className="col-7 pr-0">
                <div className="div">
                  <p className="text">Sub User Name 1</p>
                  <p className="profile">Last Seen: 01:23 am, 02/02/2021</p>
                </div>

                {/* <button className="view-notification">View Message</button> */}
              </div>
              <div
                className="col-3 px-0"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <p className=" horizontal-center">
                  <img
                    src="./images/go-arrow.png"
                    className="ms-4 h-100 ml-4"
                    alt=""
                  />
                </p>
              </div>
            </div>
            <hr className="line m-0" />

            <div className="row sub-user-box horizontal-center pb-2">
              <div className="col-2 px-0">
                <img src="./images/msg1.png" className="mr-0" alt="" />
              </div>
              <div className="col-7 pr-0">
                <div className="div">
                  <p className="text">Sub User Name 1</p>
                  <p className="profile">Last Seen: 01:23 am, 02/02/2021</p>
                </div>

                {/* <button className="view-notification">View Message</button> */}
              </div>
              <div
                className="col-3 px-0"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <p className=" horizontal-center">
                  <img
                    src="./images/go-arrow.png"
                    className="ms-4 h-100 ml-4"
                    alt=""
                  />
                </p>
              </div>
            </div>
            <hr className="line m-0" />

            <div className="row sub-user-box horizontal-center pb-2">
              <div className="col-2 px-0">
                <img src="./images/msg1.png" className="mr-0" alt="" />
              </div>
              <div className="col-7 pr-0">
                <div className="div">
                  <p className="text">Sub User Name 1</p>
                  <p className="profile">Last Seen: 01:23 am, 02/02/2021</p>
                </div>

                {/* <button className="view-notification">View Message</button> */}
              </div>
              <div
                className="col-3 px-0"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <p className=" horizontal-center">
                  <img
                    src="./images/go-arrow.png"
                    className="ms-4 h-100 ml-4"
                    alt=""
                  />
                </p>
              </div>
            </div>
            <hr className="line m-0" />

            <div className="row sub-user-box horizontal-center pb-2">
              <div className="col-2 px-0">
                <img src="./images/msg1.png" className="mr-0" alt="" />
              </div>
              <div className="col-7 pr-0">
                <div className="div">
                  <p className="text">Sub User Name 1</p>
                  <p className="profile">Last Seen: 01:23 am, 02/02/2021</p>
                </div>

                {/* <button className="view-notification">View Message</button> */}
              </div>
              <div
                className="col-3 px-0"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <p className=" horizontal-center">
                  <img
                    src="./images/go-arrow.png"
                    className="ms-4 h-100 ml-4"
                    alt=""
                  />
                </p>
              </div>
            </div>
            <hr className="line m-0" />

            <div className="row sub-user-box horizontal-center pb-2">
              <div className="col-2 px-0">
                <img src="./images/msg1.png" className="mr-0" alt="" />
              </div>
              <div className="col-7 pr-0">
                <div className="div">
                  <p className="text">Sub User Name 1</p>
                  <p className="profile">Last Seen: 01:23 am, 02/02/2021</p>
                </div>

                {/* <button className="view-notification">View Message</button> */}
              </div>
              <div
                className="col-3 px-0"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <p className=" horizontal-center">
                  <img
                    src="./images/go-arrow.png"
                    className="ms-4 h-100 ml-4"
                    alt=""
                  />
                </p>
              </div>
            </div>
            <hr className="line m-0" />

            <div className="row sub-user-box horizontal-center pb-2">
              <div className="col-2 px-0">
                <img src="./images/msg1.png" className="mr-0" alt="" />
              </div>
              <div className="col-7 pr-0">
                <div className="div">
                  <p className="text">Sub User Name 1</p>
                  <p className="profile">Last Seen: 01:23 am, 02/02/2021</p>
                </div>

                {/* <button className="view-notification">View Message</button> */}
              </div>
              <div
                className="col-3 px-0"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <p className=" horizontal-center">
                  <img
                    src="./images/go-arrow.png"
                    className="ms-4 h-100 ml-4"
                    alt=""
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-12 right-side-create-subusers">
            <p className="heading mb-4">Create New Subuser</p>
            <div className="row">
              <div className="col-lg-6 col-12">
                <input
                  type="text"
                  placeholder="Enter User Name *"
                  className="input-text mb-2"
                />

                <input
                  type="text"
                  placeholder="Enter First name *"
                  className="input-text mb-2"
                />

                <input
                  type="text"
                  placeholder="Enter Last Name *"
                  className="input-text mb-2"
                />

                <input
                  type="email"
                  placeholder="Enter email Name *"
                  className="input-text mb-2"
                />
              </div>
              <div className="col-lg-6 col-12">
                <input
                  type="text"
                  placeholder="Enter Mobile number *"
                  className="input-text mb-2"
                />

                <input
                  type="password"
                  placeholder="Enter Password *"
                  className="input-text mb-2"
                />

                <input
                  type="text"
                  placeholder="Confirm Password *"
                  className="input-text mb-2"
                />
              </div>
            </div>
            <div className="flex-center my-3">
              <button className="theme-btn px-5">Create Now</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subuser;
