import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import CreditCoins from "../pages/home/CreditCoins";
import Notification from "../pages/home/Notification";
import SearchFilters from "../pages/home/SearchEvent";
import MyProfile from "../pages/Profiles/MyProfile";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  const [profile, setProfile] = useState(false);
  const [anywhere, setAnywhere] = useState(false);
  const [creditCoins, setCreditCoins] = useState(false);
  const [searchEvent, setSearchEvent] = useState(false);
  const [popups, setPopups] = useState(0);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCreditCoins(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <>
      <Navbar bg="dark" className="header-container navbar-dark sticky-top" expand="lg">
        <Container fluid>
          <Link to="/home"
            className="navbar-brand"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            <img src="./images/logo-atzapp_white.png" alt="logo" />
          </Link>

          {/* <Form className="d-flex"> */}
          <div
            className="search-container flex-center relative"
            onClick={(e) => {
              setSearchEvent(!searchEvent);
            }}
          >
            <input
              type="text"
              placeholder="Search by Candidates/categories/Services"
              className="search-input form-control"
            />
            <img src="./images/search.png" alt="search" />
          </div>
          {searchEvent ? (
            <SearchFilters
              closePopup={() => {
                setSearchEvent(!searchEvent);
              }}
              insideClick={() => setSearchEvent(true)}
              outsideTouch={() => setSearchEvent(false)}
            />
          ) : null}
          {/* </Form> */}
          {console.log(searchEvent)}

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 ml-auto"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link to="/home" className="header-links nav-link ml-0">
                <img
                  className="mfooter-icons"
                  src="./images/trending-up.png"
                  alt=""
                />
                <p className="text-white menu-label">Home</p>
              </Link>
              {/* <Nav.Link href="#action2">Link</Nav.Link> */}

              <Link to="/vaccancy" className="header-links nav-link">
                {/* <div className=""> */}
                <img
                  className="mfooter-icons"
                  src="./images/Vector.png"
                  alt=""
                  style={{ width: "30px" }}
                />
                {/* </div> */}
                <p className="text-white menu-label">My Vaccancies</p>
              </Link>

              <Link to="/unlock-profiles" className="header-links nav-link">
                <img
                  className="mfooter-icons"
                  src="./images/person.png"
                  alt=""
                />
                <p className="text-white menu-label">Unlocked Profiles</p>
              </Link>
              <Nav.Link className="header-links">
                <div
                  className="item relative"
                  onClick={() => {
                    setPopups(3);
                  }}
                >
                  <img
                    src="./images/credit-coins-icon.png"
                    className="pr-2 img-fluid"
                    alt=""
                  />
                  <div className="">
                    <h4 className="credit-coins">200</h4>
                    <p className="header-text">Credits</p>
                  </div>
                </div>
              </Nav.Link>

              <Link to="/message" className="nav-link header-links">
                {/* <p className="text header-heading">Messages  </p> */}
                <img
                  src="./images/message.png"
                  className=" not-in-lg-header-icon "
                  alt="msg"
                />
                <p className="text-white menu-label not-in-lg-header-text">
                  Message
                </p>
              </Link>
              <Nav.Link className="header-links">
                <div className="item relative">
                  <button
                    className="btn-none header-links"
                    onClick={() => {
                      setPopups(2);
                    }}
                  >
                    {/* <p className="text header-heading">Notification  </p> */}
                    <img
                      src="./images/bell.png"
                      className="not-in-lg-header-icon "
                      style={{ paddingRight: "4px" }}
                      alt="msg"
                    />
                    <p className="text-white menu-label not-in-lg-header-text">
                      Notifications
                    </p>
                  </button>
                  {popups === 2 ? <Notification crossClose={() => { setPopups(0) }} /> : null}
                </div>
              </Nav.Link>
              <Nav.Link className="header-links">
                <div className="col px-0 flex-center relative">
                  <div
                    className="header-links mr-0"
                    onClick={() => {
                      setPopups(1);
                    }}
                  >
                    {/* <div className=""> */}
                    <img
                      className="mfooter-icons"
                      src="./images/my-profile-icon.png"
                      alt=""
                    />
                    <p className="text-white menu-label">My Profile</p>
                    {/* </div> */}
                  </div>
                  {popups === 1 ? <MyProfile closePopup={() => { setPopups(0) }} /> : null}
                  {popups === 3 ? <CreditCoins clickAnywhere={() => setAnywhere(false)} crossClose={() => { setPopups(0) }} /> : null}
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <div className="header-container space-between d-flex">
        <div className="flex-center">
          <p
            style={{
              color: "#ffffff",
              marginLeft: "40px",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            LOGO
          </p>
          <div
            className="search-container flex-center relative ml-3"
            onClick={() => {
              setSearchEvent(!searchEvent);
            }}
          >
            <input
              type="text"
              placeholder="Search by Candidates/categories/Services"
              className="search-input"
            />
            <img src="./images/search.png" alt="search" />
          </div>
          {searchEvent ? (
            <SearchFilters
              closePopup={() => {
                setSearchEvent(!searchEvent);
              }}
            />
          ) : null}
        </div>
        <div className="d-flex">
          <div className="col px-0 flex-center">
            <Link to="/home" className="header-links">
              <img
                className="mfooter-icons"
                src="./images/trending-up.png"
                alt=""
              />
              <p className="text-white menu-label">Home</p>
            </Link>
          </div>
          <div className="col px-0 flex-center">
            <Link to="/vaccancy" className="header-links">
              <img
                className="mfooter-icons"
                src="./images/Vector.png"
                alt=""
                style={{ width: "30px" }}
              />
              <p className="text-white menu-label">My Vaccancies</p>
            </Link>
          </div>
          <div className="col px-0 flex-center">
          <div className="post-footer">
            <img
              className="img-70"
              src="./images/postPlus.png"
              alt=""
            />
            <p className="text-white mt-n2">Post</p>
          </div>
        </div>
          <div className="col px-0 flex-center">
            <Link to="/unlock-profiles" className="header-links">
              <img className="mfooter-icons" src="./images/person.png" alt="" />
              <p className="text-white menu-label">Unlocked Profiles</p>
            </Link>
          </div>
          <div
            className="item row relative"
            onClick={() => {
              setCreditCoins(!creditCoins);
            }}
          >
            <img
              src="./images/credit-coins-icon.png"
              className="col px-0"
              alt=""
            />
            <div className="col px-0">
              <h4 className="credit-coins">200</h4>
              <div className="header-text">Credits</div>
            </div>
          </div>
          <div className="item">
            <Link to="/message">
              <p className="text header-heading">Messages  </p>
              <img src="./images/message.png" alt="msg" />
            </Link>
          </div>
          <div className="item relative">
            <button
              className="btn-none"
              onClick={() => {
                setNotification(!notification);
              }}
            >
              <p className="text header-heading">Notification  </p>
              <img src="./images/bell.png" alt="msg" />
            </button>
            {notification ? <Notification /> : null}
          </div>

          <div className="col px-0 flex-center relative">
            <div
              className="header-links"
              onClick={() => {
                setProfile(!profile);
              }}
            >
              <img
                className="mfooter-icons"
                src="./images/my-profile-icon.png"
                alt=""
              />
              <p className="text-white menu-label">My Profile</p>
            </div>
            {profile ? <MyProfile /> : null}
            {creditCoins ? <CreditCoins /> : null}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Header;
