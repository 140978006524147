import React from "react";

const MessageBox = (props) => {
  return (
    <>
    <div className="message-box active" onClick={() => props.onClick()}>
      <div className="row flex-center">
        <div className="col-2 pr-0 flex-center">
          <img src="./images/msg1.png" className="msg-profile" alt="" />
        </div>
        <div className="col-8 ps-0 py-2">
          <h4 className="msg-name">Rahul Dewal</h4>
          <p className="text">
            {" "}
            <span className="text-bold">Recruiter</span> Hi , I have a
            requirment on...
          </p>
        </div>
        <div
          className="col-2 pl-0 flex-center"
          style={{ flexDirection: "column" }}
        >
          <p className="text">Today</p>
          <p className="no_messages">3</p>
        </div>
      </div>
    </div>

<hr className="m-0" />
    </>

    
  );
};

export default MessageBox;
