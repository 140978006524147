import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BackBar = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="horizontal-center backBar mb-0 top-sticky-after-header" >
        <div className="backbtn horizontal-center">
          <img
            src="./images/backArrow.png"
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="backImg mr-3"
          />
          <span className="backBar-title">{props.title}</span>
        </div>
      </div>
      <hr className="my-0 " />
    </>
  );
};

export default BackBar;
