import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import VaccancyBox from './../Vaccancy/VaccancyBox'
import EditProfile from './EditProfile'

const MainProfile = () => {

  const [editProfile, setEditProfile] = useState(false);

  return (
    <div className='row'>
      <div className='col-lg-9 col-sm-12 col-md-7'>
        <div className='myProfile-details-area'>

          <div className="users-info">
            <img
              src="./images/pencilBlack.png"
              className="rightPositionTopImg"
              style={{ right: "26px",cursor:"pointer", zIndex:"9999" }}
              onClick={() => { setEditProfile(true) }}
              alt=""
            />
            <div className="row">
              <div className="col-3 px-0 ">
                <img src="./images/msg1.png" className="my-profile-user" alt="" />
              </div>
              <div className="col pr-0">
                <h3 className="user-name">Bruce Banner </h3>
                <a href="tel: +1 555 555 345" className='text'>+1 555 555 345</a> <br />
                <a href="mailto:brucebnr@gmail.com?subject = Feedback&body = Message" className='text'>brucebnr@gmail.com</a>

                <p className="myProfile-heading mt-3 mb-2">Company Name</p>
                <p className="text">
                  {" "}
                  <img src="./images/earth.png" alt="" className="mr-2" />{" "}
                  www.ahom.tech
                </p>
                <p className="text">
                  <img src="./images/location-black.png" alt="" className="mr-2" />{" "}
                  D.no-12/85-8, Street Rodger, Finland{" "}
                </p>
              </div>
            </div>

            <p className="myProfile-heading my-2"> About Company</p>
            <p className="text">
              Lorem ipsum dolor sit amet, consectadipiscing elit, sed do eiusmsit
              at,consectetur adipiscing eliem Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusm... View More
            </p>
          </div>
        </div>

        <h5 className="my-2 form-heading">My Recent Vaccancy</h5>

        <VaccancyBox cols="col-lg-6 col-md-6 col-sm-12" />
      </div>


      <div className='col-lg-3 col-sm-12 col-md-5 px-0'>
        <div className="relative">
          <div className="my-profile bg-transparent">

            {/* <div className="loginFirst">
            <div className="flex-center flex-column">
                <h2 className="heading">Welcome To Catch-Up</h2>
                <Link to="/" className="mt-4 link theme-btn btn-block w-75">Login</Link>

                <Link to="/" className="link mt-4">
                    <p className="text">Create An Account</p>
                </Link>
            </div>
        </div> */}
            <div className="">
              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/subcription-bag.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <Link to="/subcription" className="text">
                      My Subcriptions
                    </Link>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />
              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/list.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">My Lists</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />
              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/vaccancy-black.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">My Vaccancies</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />
              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/unlock-profile.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">My Unlocked Candidates</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />
              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/sub-users.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <Link to="/subusers" className="text link">
                      Sub Users
                    </Link>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />

              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/settings.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">Settings</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />

              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/bell-black.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">Alerts/Notifications</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />

              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/guard.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">Privacy & Policies</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />

              <div className="profiles-box">
                <div className="row horizontal-center">
                  <div className="col-2 px-0">
                    <div
                      className="p-2 bg-theme flex-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src="./images/contact.png" alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="text">Contact Support</p>
                  </div>
                  <div className="col-1 px-0 justify-content-end d-flex h-100">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
              <hr className="line" />
              <div className="profiles-box">
                <div className="flex-center flex-column">
                  <Link to="/" className="link theme-btn btn-block w-75">Logout</Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="my-profile mt-2">
        <div className="profiles-box">
          <div className="row">
            <div className="col-2 px-0">
              <img src="./images/user.png" alt="" />
            </div>
            <div className="col">
              <p className="text">My Subcriptions</p>
            </div>
            <div className="col-1 px-0 justify-content-end d-flex">
              <img src="./images/go-arrow.png" alt="" />
            </div>
          </div>
        </div>
        <hr className="line" />
      </div> */}
        </div>
      </div>
      {editProfile ? <EditProfile submit={() => { setEditProfile(false) }} /> : null}



    </div>
  )
}

export default MainProfile