import React, { useState } from "react";
import HireBox from "./HireBox";
import TimeSheet from "./TimeSheet";
import RecommendedProfile from "../home/component/RecommendedProfile";
import Filters from "./Filters";

import ApplicantsDetails from "./ApplicantsDetails";
import BackBar from "../home/BackBar";

const AppliedCandidates = () => {
  const [active, setActive] = useState(true);
  const [hires, setHires] = useState(false);
  const [filters, setFilters] = useState(false);
  const [timeSheet, setTimeSheet] = useState(false);

  const [detailPopup, setDetailPopup] = useState(false);

  return (
    <>
      <div className="wrapped-content-area">
      <BackBar title="Plumber Applicants & Hired"/>
        <div className="col-lg-12 col-sm-12 px-0">
          <div className=" space-between d-flex mb-2 sticky-top bg-theme px-3 align-items-center">
            <div className="d-flex ">
              <button
                onClick={() => {
                  setActive(true);
                  setHires(false);
                }}
                className={active ? "login-active" : "login"}
                style={{marginRight: "12px"}}
              >
                Applicants
              </button>
              <button
                onClick={() => {
                  setActive(false);
                  setHires(true);
                }}
                className={active ? "login" : "login-active"}
              >
                Hires
              </button>
            </div>
            <div className="filter-img-container">
              <img
                src="./images/filter-icon.png "
                onClick={() => {
                  setFilters(true);
                }}
                alt=""
              />
            </div>
          </div>
          {/* <hr className="line mt-n2 mb-3" /> */}

          {/* <VaccancyBox />  */}
          <div className="row">
            {hires ? (
              <HireBox
                closePopup={() => {
                  setTimeSheet(true);
                }}
              />
            ) : (
              <RecommendedProfile openDetailPopup={() => setDetailPopup(true)} />
            )}
            {timeSheet ? (
              <TimeSheet
                closePopup={() => {
                  setTimeSheet(false);
                }}
              />
            ) : null}
          </div>
        </div>
        {filters ? (
          <Filters
            closePopup={() => {
              setFilters(false);
            }}
          />
        ) : null}
        {detailPopup ? 
        <ApplicantsDetails closePopup={() => setDetailPopup(false)} /> : null }
      </div>
    </>
  );
};

export default AppliedCandidates;
