import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl, vaccancy_url, experiance_url } from "../../Service/service";
import NoVaccancy from "../../common/NoVaccancy";
import { getData } from "../../Service/ApiMethod";

const VaccsncyBox = (props) => {

  const [vaccancy, setVaccancy] = useState();
  const [isErr, setIsErr] = useState();
  const [experience, setExperience] = useState([]);

  // const handleVaccancy = () => {
  //   try {
  //     const res = await axios.get(baseUrl + "/recruiter/job_post/");
  //     console.log(res.data.data);
  //     setVaccancy(res.data);
  //   }
  //   catch (err) {
  //     console.log(" code", err.message);
  //   }
  // }
  // }

  const getExperience = () => {
    getData(experiance_url)
      .then((response) => response.json())
      .then((data) => {
        setExperience(data.data);
      })
      .catch((error) => {
        console.log("Error in categories from Post Form", error);
      });
  };
  console.log(experience[0]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(baseUrl + vaccancy_url, { headers: { 'Authorization': "Bearer " + localStorage.getItem("access token login") } });
        console.log(res.data);
        setVaccancy(res.data.data);

        console.log("in vacancy   State", vaccancy);
      }
      catch (err) {
        console.log(" code", err);
        setIsErr(err)
      }

    }
    getExperience();
    fetchData();

  }, [])
  let Package;
  let PackageTo;
  return (
    <>
      {isErr != null ?
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="recommended-profile p-2 px-0">
            <div className="row">
              <div className="col-4 px-0 relative flex-center vaccancy-pic-area">
                <img src="./images/err.jpg" alt="" style={{ width: "78px", borderRadius: "50%", height: "78px" }} className="vaccancy-pic" />
                <div className="profile-match horizontal-center px-2">
                  <img src="./images/blue-eye.png" className="mr-1" alt="" />
                  <span className="match-score">503 Error Code</span>
                </div>
              </div>
              <div className="col-8 pr-0 mt-1">
                <h2 className="card-heding text-danger relative">
                  {isErr.message}
                </h2>
                <p className="designation mt-2 text-primary">Soory!! We are facing some issues.</p>
                <div className="row mt-1">
                  <div className="col-6 px-0">
                    <p className="card-subHeading">Error Name</p>
                    <p className="card-subHeading">Code</p>
                    <p className="card-subHeading">Method</p>
                  </div>
                  <div className="col-6 pl-0">
                    <p className="profile-info">{isErr.name}</p>
                    <p className="profile-info">{isErr.code}</p>
                    <p className="profile-info">{isErr.config.method}</p>
                  </div>
                  <div className="col-6 px-0">
                    <p className="horizontal-center">
                    </p>
                  </div>
                  <div className="col-6 pl-0">
                    <p className="post-day text-right">Solving it Soon</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        :
        vaccancy?.map((item, index) => {
          const salary = Number(item.compensation_amount);

          if (salary > 1000) {
            Package = Math.floor((salary / 1000)) + "k";
            PackageTo = (Math.floor((salary + 5000) / 1000)) + "k";

          } else {
            Package = salary;
            PackageTo = salary + 1000;
          }


          if (item.compensation_details === "Monthly") {
            item.compensation_details = "M"
          } else if (item.compensation_details === "Yearly") {
            item.compensation_details = "Y"

          } else if (item.compensation_details === "Daily") {
            item.compensation_details = "Day"

          } else if (item.compensation_details === "Weekly") {
            item.compensation_details = "W"

          }
          if (item.length === 0) {
            return (
              <NoVaccancy />
            )
          } else {
            return (
              <div className={props.cols} key={index}>
                <Link to="/applicants" className=" link ">

                  <div className="recommended-profile p-2 px-0">
                    <div className="row">
                      <div className="col-4 px-0 relative flex-center vaccancy-pic-area">
                        <img src="./images/plumber.png" alt="" className="vaccancy-pic" />
                        <div className="profile-match horizontal-center px-2">
                          <img src="./images/blue-eye.png" className="mr-1" alt="" />
                          <span className="match-score">{item.maximum_application_allowled} Applications</span>
                        </div>
                      </div>
                      <div className="col-8 pr-0 mt-1">
                        <h2 className="card-heding relative">
                          {item.title}
                          <img
                            src="./images/pencilBlack.png"
                            alt=""
                            className="addprofile"
                          />
                        </h2>
                        <p className="designation mt-2">
                          {
                            item.urgent_vacancy ?
                              <span>Required {item.title} Immediately</span> :
                              <span>Not Required Immediately </span>
                          }
                        </p>
                        <div className="row mt-1">
                          <div className="col-6 px-0">
                            <p className="card-subHeading">Total Experience</p>
                            <p className="card-subHeading">Work Location</p>
                            <p className="card-subHeading">Package</p>
                          </div>
                          <div className="col-6 px-0">
                            <p className="profile-info">
                              {console.log(item.experiance)}
                              Yea </p>
                            <p className="profile-info">{item.work_location}</p>
                            <p className="profile-info"> ${Package}-${PackageTo}</p>
                          </div>
                          <div className="col-6 px-0">
                            <p className="horizontal-center">
                              {/* <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span> */}
                            </p>
                          </div>
                          <div className="col-6 px-0">
                            <p className="post-day text-right">Posted 1 day ago</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <hr />
              </div>
            )
          }
        })
      }

      {/* <div className="col-lg-4 col-md-6 col-sm-12">
      <Link to="/applicants" className=" link ">
        <div className="recommended-profile p-2 px-0">
          <div className="row">
            <div className="col-4 px-0 relative flex-center vaccancy-pic-area">
              <img src="./images/plumber.png" alt="" className="vaccancy-pic" />
              <div className="profile-match horizontal-center px-2">
                <img src="./images/blue-eye.png" className="mr-1" alt="" />
                <span className="match-score">25 Applications</span>
              </div>
            </div>
            <div className="col-8 pr-0 mt-1">
              <h2 className="card-heding relative">
                Plumber
                <img
                  src="./images/pencilBlack.png"
                  alt=""
                  className="addprofile"
                />
              </h2>
              <p className="designation mt-2">Required Plumbers Immediately</p>
              <div className="row mt-1">
                <div className="col-6 px-0">
                  <p className="card-subHeading">Total Experience</p>
                  <p className="card-subHeading">Work Location</p>
                  <p className="card-subHeading">Package</p>
                </div>
                <div className="col-6 pl-0">
                  <p className="profile-info">1.5 Years </p>
                  <p className="profile-info">Finland</p>
                  <p className="profile-info"> $700-$1000/m </p>
                </div>
                <div className="col-6 px-0">
                  <p className="horizontal-center">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="col-6 pl-0">
                  <p className="post-day text-right">Posted 1 day ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <hr />
    </div>

    <div className="col-lg-4 col-md-6 col-sm-12">
      <Link to="/applicants" className=" link ">
        <div className="recommended-profile p-2 px-0">
          <div className="row">
            <div className="col-4 px-0 relative flex-center vaccancy-pic-area">
              <img src="./images/plumber.png" alt="" className="vaccancy-pic" />
              <div className="profile-match horizontal-center px-2">
                <img src="./images/blue-eye.png" className="mr-1" alt="" />
                <span className="match-score">25 Applications</span>
              </div>
            </div>
            <div className="col-8 pr-0 mt-1">
              <h2 className="card-heding relative">
                Plumber
                <img
                  src="./images/pencilBlack.png"
                  alt=""
                  className="addprofile"
                />
              </h2>
              <p className="designation mt-2">Required Plumbers Immediately</p>
              <div className="row mt-1">
                <div className="col-6 px-0">
                  <p className="card-subHeading">Total Experience</p>
                  <p className="card-subHeading">Work Location</p>
                  <p className="card-subHeading">Package</p>
                </div>
                <div className="col-6 pl-0">
                  <p className="profile-info">1.5 Years </p>
                  <p className="profile-info">Finland</p>
                  <p className="profile-info"> $700-$1000/m </p>
                </div>
                <div className="col-6 px-0">
                  <p className="horizontal-center">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="col-6 pl-0">
                  <p className="post-day text-right">Posted 1 day ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <hr />
    </div> */}

      {/* <div className="col-lg-4 col-md-6 col-sm-12">
      <Link to="/applicants" className=" link ">
        <div className="recommended-profile p-2 px-0">
          <div className="row">
            <div className="col-4 px-0 relative flex-center vaccancy-pic-area">
              <img src="./images/plumber.png" alt="" className="vaccancy-pic" />
              <div className="profile-match horizontal-center px-2">
                <img src="./images/blue-eye.png" className="mr-1" alt="" />
                <span className="match-score">25 Applications</span>
              </div>
            </div>
            <div className="col-8 pr-0 mt-1">
              <h2 className="card-heding relative">
                Plumber
                <img
                  src="./images/pencilBlack.png"
                  alt=""
                  className="addprofile"
                />
              </h2>
              <p className="designation mt-2">Required Plumbers Immediately</p>
              <div className="row mt-1">
                <div className="col-6 px-0">
                  <p className="card-subHeading">Total Experience</p>
                  <p className="card-subHeading">Work Location</p>
                  <p className="card-subHeading">Package</p>
                </div>
                <div className="col-6 pl-0">
                  <p className="profile-info">1.5 Years </p>
                  <p className="profile-info">Finland</p>
                  <p className="profile-info"> $700-$1000/m </p>
                </div>
                <div className="col-6 px-0">
                  <p className="horizontal-center">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="col-6 pl-0">
                  <p className="post-day text-right">Posted 1 day ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <hr />
    </div> */}

    </>
  )
};

export default VaccsncyBox;
