import React, { useState } from "react";
import AddToList from "../../Vaccancy/AddToList";
import profileData from "./ProfileData";

const RecommendedProfile = (props) => {
  const [popup, setPopup] = useState(false);
  return (
    <>
      {profileData.map((item, index) => {
        return (
          <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
            <div className="recommended-profile">
              <div className="row">
                <div className="col-4 px-0 relative flex-center">
                  <img
                    src={item.profile_pic}
                    alt=""
                    className="profile-img"
                  />
                  <img
                    src="./images/save-profile.png"
                    alt=""
                    className="save-profile"
                  />
                  {props.bottom}
                </div>
                <div className="col-8 pr-0">
                  <h2 className="card-heding relative">
                    <span style={{ cursor: "pointer" }} onClick={() => props.openDetailPopup()} >{item.candidate_name}</span>
                    <img
                      src="./images/send-job.png"
                      alt=""
                      className="addprofile"
                      onClick={() => { setPopup(true) }}
                    />
                    <img src="./images/verified.png" alt="" />
                    <span className="verified">Verified</span>
                  </h2>
                  <p className="designation">{item.designation}</p>
                  <p className="profile-availbility">
                    (Available {item.available_hours})
                  </p>
                  <div className="row mt-2">
                    <div className="col-6 px-0">
                      <p className="card-subHeading">Total Experience</p>
                      <p className="card-subHeading">Current Location</p>
                      <p className="card-subHeading">Package</p>
                      <p className="horizontal-center">
                        <img
                          src="./images/match-badge.png"
                          className="img-size18"
                          alt=""
                        />
                        <span className="ms-1 match-profile">
                          {item.profile_match_percentage} Match
                        </span>
                      </p>
                    </div>
                    <div className="col-6 px-0">
                      <p className="profile-info">{item.total_experience} </p>
                      <p className="profile-info">{item.curr_location} </p>
                      <p className="profile-info">{item.package} </p>
                      <p className="profile-last-seen">
                        <span className="last-seen">last Seen</span>
                        {item.last_seen_status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {popup ? <AddToList closePopup={() => { setPopup(false) }} /> : null}
    </>
  );
};

export default RecommendedProfile;
