import React from "react";
import { Link } from "react-router-dom";

const MyProfile = (props) => {
  return (
    <div className="my-profile-container relative">
      <button className="btn-none close-popup-btn" onClick={props.closePopup}>x</button>
      <div className="my-profile ">
        <div className="users-info">
          <img
            src="./images/pencilBlack.png"
            className="rightPositionTopImg"
            alt=""
          />
          <div className="row">
            <div className="col-3 px-0 ">
              <img src="./images/msg1.png" className="my-profile-user" alt="" />
            </div>
            <div className="col pr-0">
              <h3 className="user-name">Bruce Banner </h3>
              <p className="text">+1 555 555 345</p>
              <p className="text">brucebnr@gmail.com</p>
            </div>
          </div>
          <p className="myProfile-heading mt-3 mb-2">Company Name</p>
          <p className="text">
            {" "}
            <img src="./images/earth.png" alt="" className="mr-2" />{" "}
            www.ahom.tech
          </p>
          <p className="text">
            <img src="./images/location-black.png" alt="" className="mr-2" />{" "}
            D.no-12/85-8, Street Rodger, Finland{" "}
          </p>
          <p className="myProfile-heading my-2"> About Company</p>
          <p className="text">
            Lorem ipsum dolor sit amet, consectadipiscing elit, sed do eiusmsit
            at,consectetur adipiscing eliem Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusm... View More
          </p>
        </div>

        {/* <div className="loginFirst">
            <div className="flex-center flex-column">
                <h2 className="heading">Welcome To Catch-Up</h2>
                <Link to="/" className="mt-4 link theme-btn btn-block w-75">Login</Link>

                <Link to="/" className="link mt-4">
                    <p className="text">Create An Account</p>
                </Link>
            </div>
        </div> */}

        <hr />
        <div className="">
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/subcription-bag.png" alt="" />
                </div>
              </div>
              <div className="col">
                <Link to="/subcription" className="text">
                  My Subcriptions
                </Link>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/list.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">My Lists</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/vaccancy-black.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">My Vaccancies</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/unlock-profile.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">My Unlocked Candidates</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/sub-users.png" alt="" />
                </div>
              </div>
              <div className="col">
                <Link to="/subusers" className="text link">
                  Sub Users
                </Link>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/settings.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Settings</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/bell-black.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Alerts/Notifications</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/guard.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Privacy & Policies</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 bg-theme flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/contact.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Contact Support</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="flex-center flex-column">
              <Link to="/" className="link theme-btn btn-block w-75">Logout</Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="my-profile mt-2">
        <div className="profiles-box">
          <div className="row">
            <div className="col-2 px-0">
              <img src="./images/user.png" alt="" />
            </div>
            <div className="col">
              <p className="text">My Subcriptions</p>
            </div>
            <div className="col-1 px-0 justify-content-end d-flex">
              <img src="./images/go-arrow.png" alt="" />
            </div>
          </div>
        </div>
        <hr className="line" />
      </div> */}
    </div>
  );
};

export default MyProfile;
