import React from 'react'

const WorkDetail = () => {
  return (
    <>
        <h5 className='profile-info text-left'>Task Description</h5>
        <p className="text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum accusamus commodi minus.</p>
    </>
  )
}

export default WorkDetail