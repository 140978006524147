import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./jobseeker.css";

const HeaderSimple = () => {
  // const wrapperRef = useRef(null);
  return (
    <>
      <Navbar
        bg="dark"
        className="recruiter-header-container navbar-dark sticky-top"
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand
            href="#"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            Logo
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderSimple;
