import React, { useState } from "react";
import BackBar from "../home/BackBar";
import UnlockedProfileBox from "./UnlockedProfileBox";
import AddToList from "../Vaccancy/AddToList";

const UnlockedProfile = () => {
  const [createList, setCreateList] = useState(false);
  return (
    <>
      {/* <Header /> */}
      <div className="wrapped-content-area">
      <BackBar title="Unlocked Profile"/>
        <div className="container-fluid">
          {/* <div
            className="search-container flex-center relative"
            style={{ margin: "auto", marginTop: "10px" }}
          >
            <input
              type="text"
              placeholder="Search by Candidates/categories/Services"
              className="search-input"
            />
            <img src="./images/search.png" alt="search" />
          </div> */}

          {/* <hr className="mb-0 mt-1" /> */}

          <div className="hashtags-keys">
            <p className="filters">Filters:- </p>
            <button className="active-tag ">All</button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Unread
            </button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              First Round
            </button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Second Round
            </button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Rejected
            </button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Selected
            </button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Shortlisted
            </button>
            <button
              className="deactive-tags"
              style={{ fontSize: "12px" }}
            >
              Freshers
            </button>
          </div>
          <hr className="mb-0 mt-2" />

          <div className="mt-3">
            <div className="row align-items-center justify-content-center">
              <UnlockedProfileBox
                addList={() => {
                  setCreateList(!createList);
                }}
              />
              <UnlockedProfileBox
                addList={() => {
                  setCreateList(!createList);
                }}
              />
              <UnlockedProfileBox
                addList={() => {
                  setCreateList(!createList);
                }}
              />
              <UnlockedProfileBox
                addList={() => {
                  setCreateList(!createList);
                }}
              />
              {createList ? (
                <AddToList
                  closePopup={() => {
                    setCreateList(!createList);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnlockedProfile;
