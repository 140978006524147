// Local And Server Base Urls

// export const baseUrl = "https://jobportalapi.atwpl.com";
export const baseUrl = "https://jobportalapi.atwpl.com";


// End Points

export const registrationUrl = "/register/";
export const loginUrl = "/login/";
export const job_category_url = "/JobCategories/";
export const skills_url = "/skills/"
export const experiance_url = "/experience/"
export const degree_url = "/degree/"
export const vaccancy_url = "/post_vacancy/"

