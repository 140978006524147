import React from "react";
import { Route, Routes, BrowserRouter, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/styles.css";
import Home from "./pages/home/Home";
import Messages from "./pages/Message/Messages";
import Vaccancy from "./pages/Vaccancy/Vaccancy";
import LoginPage from "./pages/login/LoginPage";
import UnlockedProfile from "./pages/UnlockedProfiles/UnlockedProfile";
import MainProfile from "./pages/Profiles/MainProfile";

import HeaderAfterLogin from "./common/HeaderAfterLogin";
import HeaderBeforeLogin from "./common/HeaderBeforeLogin";
import Footer from "./common/Footer";
import PostForm from "./pages/PostVaccancy/PostForm";
import Successfully from "./common/Successfully";
import NoVaccancy from "./common/NoVaccancy";
import AppliedCandidates from "./pages/Vaccancy/AppliedCandidates";
import Subcription from "./pages/subcription/Subcription";
import ApplicantsDetails from "./pages/Vaccancy/ApplicantsDetails";
import PostSuccess from "./common/PostSuccess";
import RecommendedProfile from "./pages/Recommende-profile/RecommendedProfile";
import PaymentSuccess from './pages/Notification/PaymentSuccessPopup'
import Calendar from "./pages/Vaccancy/Calendar";
import Subuser from "./pages/Notification/Subuser";
import RecruiterHome from "./Jobseeker/JobseekerHome";
import Header from "./Jobseeker/Header";
import RecruiterFooter from "./Jobseeker/JobseekerFooter";
import Myob from "./Jobseeker/Myjob/Myob";
// import JobseekerLogin from "./Jobseeker/Login/JobseekerLogin";
import HeaderSimple from "./Jobseeker/HeaderSimple";
import JobseekerLoginPage from "./Jobseeker/Login/JobseekerLoginPage";
import JobseekerMessage from "./Jobseeker/Message/JobseekerMessage";
import Profile from "./Jobseeker/Profile/Profile";
import JobseekerCalendar from "./Jobseeker/Myjob/Calender";
import Registration from "./common/Registration";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ScreensBeforeLogin(<LoginPage />)} />
          <Route exact path="/home" element={ScreensAfterLogin(<Home />)} />
          <Route path="/message" element={<Messages />} />
          <Route path="/vaccancy" element={ScreensAfterLogin(<Vaccancy />)} />
          <Route path="/registration" element={ScreensBeforeLogin(<Registration />)} />
          <Route path="/home" element={ScreensAfterLogin(<Home />)} />
          <Route path="/home" element={ScreensAfterLogin(<Home />)} />
          <Route path="/MainProfile" element={ScreensAfterLogin(<MainProfile />)} />
          <Route path="/post-vaccancy" element={ScreensAfterLogin(<PostForm />)} />
          <Route path="/success" element={ScreensAfterLogin(<Successfully />)} />
          <Route path="/no-data" element={ScreensAfterLogin(<NoVaccancy />)} />
          <Route path="/applicants-details" element={ScreensAfterLogin(<ApplicantsDetails />)} />
          <Route path="/subcription" element={ScreensAfterLogin(<Subcription />)} />
          <Route path="/applicants" element={ScreensAfterLogin(<AppliedCandidates />)} />
          <Route path="/vaccancy-posted" element={<AppliedCandidates />} />
          <Route path="/post-success" element={ScreensAfterLogin(<PostSuccess />)} />
          <Route path="/payment-success" element={ScreensAfterLogin(<PaymentSuccess />)} />
          <Route path="/recommended-profiles" element={ScreensAfterLogin(<RecommendedProfile />)} />
          <Route path="/unlock-profiles" element={ScreensAfterLogin(<UnlockedProfile />)} />
          <Route path="/Calender" element={ScreensAfterLogin(<Calendar />)} />
          <Route path="/subusers" element={ScreensAfterLogin(<Subuser />)} />
          <Route path="/jobseeker-login" element={ScreenJobseekerBeforeLogin(<JobseekerLoginPage />)} />
          <Route path="/jobseeker-home" element={ScreenJobseekerAfterLogin(<RecruiterHome />)} />
          <Route path="/jobseeker-myJob" element={ScreenJobseekerAfterLogin(<Myob />)} />
          <Route path="/jobseeker-message" element={ScreenJobseekerAfterLogin(<JobseekerMessage />)} />Profile

          <Route path="/jobseeker-profile" element={ScreenJobseekerAfterLogin(<Profile />)} />
          <Route path="/jobseeker-calender" element={ScreenJobseekerAfterLogin(<JobseekerCalendar />)} />



          {/* URL Lists */}


        </Routes>
      </BrowserRouter>
      {/* <Home/> */}
      {/* <Messages/> */}
    </>
  );
}

export default App;

const ScreensAfterLogin = (element) => {
  return (
    <div className="main-container">
      <HeaderAfterLogin />
      <div className="wrapped-content-area">{element}</div>
      <div className="fixed-new-post">
        <Link to="/post-vaccancy" className="flex-center" title="Add New Post">
          {/* <img
            className="mfooter-icons"
            src="./images/postPlus.png"
            alt=""
          /> */}
          +
        </Link>
      </div>
      <Footer />
    </div>
  );
};

const ScreensBeforeLogin = (element) => {
  return (
    <div className="main-container">
      <HeaderBeforeLogin />
      <div className="wrapped-content-area">{element}</div>
      <Footer />
    </div>
  );
};
const ScreenJobseekerAfterLogin = (element) => {
  return (
    <div className="jobseeker-container">
      <Header />

      <div className="wrapped-content-area">{element}</div>
      <RecruiterFooter />
    </div>
  )
}
const ScreenJobseekerBeforeLogin = (element) => {
  return (
    <div className="jobseeker-container">
      <HeaderSimple />

      <div className="wrapped-content-area">{element}</div>
      <RecruiterFooter />
    </div>
  )
}
