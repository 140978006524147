import React, { useState } from "react";

const SearchFilters = (props) => {
  const [experiance, setExperience] = useState(2);
  const [distance, setDistance] = useState(12);
  const [age, setAge] = useState(22);
  const [salary, setSalary] = useState(3);
  const [isActive, setIsActive] = useState();
  const [employeType, setEmployeType] = useState();
  const [gender, setGender] = useState()




  return (
    <div className="blur-screen-full-area flex-center" onClick={props.outsideTouch}>
      <div className="search-events">
        <div className="search-filters" onClick={props.insideClick}>
          <h2 className="heading mb-3 text-center">Search Candidates</h2>
          <div className="search-container flex-center relative ml-3 mb-2">
            <input
              type="text"
              placeholder="Search by Candidates/categories/Services"
              className="search-input"
              style={{ border: "1px solid grey" }}
            />
            <img src="./images/search.png" alt="search" />
          </div>

          <p className="popup-heading">Looking</p>

          <div className="looking-keys mt-1 mb-3">
            <button
              className={isActive === "plumber" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="plumber"
              onClick={(e) => setIsActive(e.target.id)}>Plumber</button>
            <button
              className={isActive === "Painter" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Painter"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Painter
            </button>
            <button
              className={isActive === "Carpainter" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Carpainter"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Carpainter
            </button>
            <button
              className={isActive === "Driver" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Driver"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Driver
            </button>
            <button
              className={isActive === "Technican" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Technican"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Technican
            </button>
            <button
              className={isActive === "Nurse" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Nurse"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Nurse
            </button>
            <button
              className={isActive === "Construction" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Construction"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Construction
            </button>
            <button
              className={isActive === "Electrician" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Electrician"
              onClick={(e) => setIsActive(e.target.id)}
            >
              Electrician
            </button>
          </div>

          <select name="" id="" className="select-input w-100 mb-3">
            <option value="Plumbing">Select Required Skills *</option>

            <option value="Plumbing">Plumbing</option>
            <option value="Plumbing">Painting</option>
            <option value="Plumbing">Building Plumbering</option>
          </select>

          <p className="popup-heading">Employment Type</p>

          <div className="looking-keys my-3">
            <button
              className={employeType === "part_time" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="part_time"
              onClick={(e) => setEmployeType(e.target.id)}
            >
              Part Time
            </button>
            <button
              className={employeType === "full_time" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="full_time"
              onClick={(e) => setEmployeType(e.target.id)}
            >
              Full Time
            </button>

            <button
              className={employeType === "contract" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="contract"
              onClick={(e) => setEmployeType(e.target.id)}
            >
              Contract
            </button>
          </div>

          <p className="popup-heading">Gender</p>

          <div className="looking-keys my-3">
            <button
              className={gender === "Male" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Male"
              onClick={(e) => setGender(e.target.id)}
            >
              Male
            </button>
            <button
              className={gender === "Female" ? "theme-btn" : "theme-btn deactive-tags"}
              style={{ fontSize: "12px" }}
              id="Female"
              onClick={(e) => setGender(e.target.id)}
            >
              Female
            </button>
          </div>

          <div className="range-box mb-3">
            <div className="space-between d-flex">
              <p className="popup-heading">Experience</p>
              <p className="range-details"> {Number(experiance)}-{Number(experiance) + Number(2)} Years</p>
            </div>
            <input
              type="range"
              name="experience"
              id="experience"
              min="0" max="25"
              value={experiance}
              className="w-100 mt-3"
              onChange={(e) => setExperience(e.target.value)} />
          </div>
          <div className="range-box mb-3">
            <div className="space-between d-flex">
              <p className="popup-heading">Maximum Distance</p>
              <p className="range-details"> {Number(distance)} KM</p>
            </div>
            <input
              type="range" name="distance"
              id="distance"
              min="0" max="100"
              value={distance}
              className="w-100 mt-3"
              onChange={(e) => setDistance(e.target.value)} />
          </div>
          <div className="range-box mb-3">
            <div className="space-between d-flex">
              <p className="popup-heading">Age</p>
              <p className="range-details"> {Number(age)}-{Number(age) + Number(2)} Years</p>
            </div>
            <input
              type="range"
              name="age"
              id="age"
              min="18" max="58"
              value={age}
              className="w-100 mt-3"
              onChange={(e) => setAge(e.target.value)} />
          </div>
          <div className="range-box mb-3">
            <div className="space-between d-flex">
              <p className="popup-heading">Salary</p>
              <p className="range-details"> {Number(salary)}k-{Number(salary) + Number(2)}k</p>
            </div>
            <input
              type='range'
              name="salary"
              id="salary"
              min="2" max="99"
              value={salary}
              className="w-100 mt-3"
              onChange={(e) => setSalary(e.target.value)} />
          </div>
          <p className="popup-heading">Candidate Type</p>

          <div className="d-flex horizontal-center my-2">
            <input type="checkbox" style={{ color: "#96989A" }} className="mr-1" name="" id="" />
            <p className="text mr-4" style={{ color: "#96989A" }}>Free</p>
            <input type="checkbox" className="mr-1" name="" id="" style={{ color: "#96989A" }} />

            <p className="text mr-4" style={{ color: "#96989A" }}>Verified</p>
            <input type="checkbox" className="mr-1" name="" id="" style={{ color: "#96989A" }} />

            <p className="text mr-4" style={{ color: "#96989A" }}>Premium</p>
          </div>
          <hr className="line" />

          <button className="theme-btn btn-block" onClick={props.closePopup}>
            {" "}
            Serch Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
