import React from "react";

const ProfessionalInfo = (props) => {
  return (
    <>
      <h5 className="mb-3">Professional Information</h5>
      <div className="Employment-Details mt-3">
        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Select Category *"
        >
          <option value="0">Select Category *</option>
          <option value="1">Plumber</option>
          <option value="2">Technician</option>
          <option value="3">Carpainter</option>
        </select>

        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Select Job Category *"
        >
          <option value="0">Select Required Skills *</option>
          <option value="1">Plumber</option>
          <option value="2">Technician</option>
          <option value="3">Carpainter</option>
        </select>

        <input
          type="text"
          placeholder="Enter Your Location"
          className="input-text pl-3 w-100 mt-2"
        />
        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Select Your Experience *"
        >
          <option value="0">Select Your Experience *</option>
          <option value="1">1 Years</option>
          <option value="2">3 Years</option>
          <option value="3">5 Years</option>
        </select>
        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Select Highest Education *"
        >
          <option value="0">Select Highest Education *</option>
          <option value="1">10th/12th</option>
          <option value="2">UG</option>
          <option value="3">PG</option>
        </select>

        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Passout Year *"
        >
          <option value="0">Passout Year *</option>
          <option value="1">2010</option>
          <option value="2">2012</option>
          <option value="3">2014</option>
        </select>
        <input
          type="file"
          placeholder="Upload Certificates   "
          className="input-text pl-3 w-100 mt-2"
        />
        <p className="text text-right mb-3" style={{opacity: "0.5"}}>You can upload multiple certificates</p>

        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Employment Type *"
        >
          <option value="0">Employment Type *</option>
          <option value="1">Full Time</option>
          <option value="2">Part Time</option>
          <option value="3">Full Time</option>
        </select>

        <select
          id="cars"
          className="input-text pl-3 w-100 mb-2"
          placeholder="Expected Salary *"
        >
          <option value="0">Expected Salary *</option>
          <option value="1">25k - 30k</option>
          <option value="2">40k - 50k</option>
          <option value="3">Custom</option>
        </select>

        <textarea
          name=""
          id=""
          cols=""
          className="input-text pl-3 w-100 mb-2"
          rows="4"
          placeholder="Cover Letter/ About Yourself"
          style={{ height: "120px" }}
        ></textarea>
        <button className="mt-3 btn-block jobseeker-btn" onClick={props.nextStep}>Next</button>
      </div>
    </>
  );
};

export default ProfessionalInfo;
