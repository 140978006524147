import React from "react";
import { location_of_candidates } from "../pages/home/component/ProfileData";
import { Link } from "react-router-dom";
import AppliedJobs from "./Myjob/AppliedJobs";
import UnlockedProfiles from "./UnlockedProfiles";
import OngoingJobs from "./OngoingJobs";
import JobseekerRecommendedJobs from "./JobseekerRecommendedJobs";

const JobseekerHome = () => {
  return (
    <div>
      <div className="d-flex space-between mt-3 px-3">
        <h1 className="heading"> Ongoing Jobs</h1>
        <a href="./jobseeker-myJob" className="jobseeker-view-all">
          View All
        </a>
      </div>

      <div className="row mt-3">
        <OngoingJobs />
      </div>

      {/* Subcription */}

      <div className="flex-center mt-3 subcription-section">
        <div className="subcription-box">
          <div className="row">
            <div className="col-8 px-0">
              <h3 className="sub-heading mb-2">Your Subscription Info</h3>
              <p className="text mb-3">
                Lorem ipsum dolor sit amet, consectadipi sed do eiusm sed do
                eiusmo tem Lorem ipsum dolor.
              </p>
              <Link
                to="/subcription"
                className="jobseeker-btn-dark mt-3"
                // onClick={() => {
                //   setPopup(true);
                // }}
              >
                Upgrade your Subscription
              </Link>
            </div>
            <div className="col-4 px-0 flex-center">
              <img src="./images/rafiki.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* //Subcription */}
      <div className="d-flex space-between mt-3 px-3">
        <h1 className="heading"> Recommended Jobs</h1>
        <a href="./unlocked-profile" className="jobseeker-view-all">
          View All
        </a>
      </div>

      <div className="row mt-3">
        <JobseekerRecommendedJobs />
      </div>

      <div className="mt-3 complete-profile-section flex-center">
        <div
          className="profile-completion-box flex-center"
          style={{ flexDirection: "column" }}
        >
          <div className="row">
            <div className="col-5 flex-center">
              <div className="completion-graph flex-center">
                <img src="./images/graph-profile.png" alt="" className="" />
              </div>
            </div>

            <div className="col-7 align-items-start d-flex flex-column">
              <h2 className="profile-heading ">Complete Your Profile</h2>
              <div className="about-profile text-left">
                Lorem ipsum dolor sit amet, consectadipi sed do eiusm sed do
                eiusmo tem Lorem ipsum dolor{" "}
              </div>
              <a href="/jobseeker-profile" className="jobseeker-btn mt-3">
                Complete Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex space-between mt-3 px-3">
        <h1 className="heading"> Unlocked by Recruiter</h1>
        <a href="./jobseeker-myJob" className="jobseeker-view-all">
          View All
        </a>
      </div>

      <div className="row mt-3">
        <UnlockedProfiles />
      </div>

      <div className="d-flex space-between my-3 px-3">
        <div className="relative">
          <h1 className="heading">Applied Jobs</h1>
          <p className="text">
            Lorem ipsum dolor sit amet, consectadipi sed do eiusm
          </p>
        </div>
        <button
          className="jobseeker-view-all btn-none"
          // onClick={() => {
          //   setLocation(!location);
          // }}
        >
          View All
        </button>
      </div>
      <div className="row">
        <AppliedJobs />
      </div>



      <div className="d-flex space-between mt-3 px-3">
        <div className="relative">
          <h1 className="heading">Jobs Near Me</h1>
          <p className="text">
            Lorem ipsum dolor sit amet, consectadipi sed do eiusm
          </p>
        </div>
        <button
          className="jobseeker-view-all btn-none"
          // onClick={() => {
          //   setLocation(!location);
          // }}
        >
          View All
        </button>
      </div>

      <div className="row mt-3">
        {location_of_candidates.map((item, index) => {
          return (
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="nearMe-box">
                <div className="row">
                  <div className="col-2 px-0">
                    <div className="location-img flex-center">
                      <img
                        src="./images/location.png"
                        alt=""
                        className="location-icon"
                      />
                    </div>
                  </div>
                  <div className="col-8 pr-0">
                    <h2 className="location-heading">
                      Within <span>{item.distance}</span>m radius
                    </h2>
                    <p className="text">{item.available_candidates} Jobs</p>
                  </div>
                  <div className="col-2 px-0 flex-center">
                    <img src="./images/go-arrow.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobseekerHome;
