import React from 'react'

const Package = (props) => {
    return (
        <div className="filter-collapse" style={{ width: props.widthhh }}>
            <div className="d-flex space-between">
                <p className="toggle-text">Less Than Equal to 10k Year</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">12k - 15k </p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">16-20 k</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">21 - 25k</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">26 - 30 k</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">31 - 35k </p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">36k+ </p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

        </div>
    );
}

export default Package