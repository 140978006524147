import React from "react";
import {location_of_candidates} from "./ProfileData";

const Nearby = () => {
    return(
      location_of_candidates.map((item, index) => {
      return (
        <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
          <div className="nearMe-box">
            <div className="row">
              <div className="col-2 px-0">
                <div className="location-img flex-center">
                  <img
                    src="./images/location.png"
                    alt=""
                    className="location-icon"
                  />
                </div>
              </div>
              <div className="col-8 pr-0">
                <h2 className="location-heading">Within <span>{item.distance}</span>m radius</h2>
                <p className="text">{item.available_candidates} Candidates</p>
              </div>
              <div className="col-2 px-0 flex-center">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    })
    )
};

export default Nearby;
