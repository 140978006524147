import React, { useState } from "react";
import WorkDetail from "./WorkDetail";

const TimeSheet = (props) => {
  const [work, setWork] = useState(false);
  return (
    <>
      <div className="blur-screen-full-area flex-center">
        <div className="add-to-list-box py-0">
          <div className="text-center bg-white sticky-top">
            <p className="heading mt-2">Time Sheet</p>
          </div>
          <div className="div my-3">
            <p className="hired-designation">February 2021 (6 Days)</p>
          </div>
          <hr className="line" />

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <hr className="line" />

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <hr className="line" />

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button
              className="toggle-btn"
              onClick={() => {
                setWork(!work);
              }}
            >
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          {work ? <WorkDetail /> : null}

          <hr className="line" />

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <hr className="line" />
          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <hr className="line" />

          <div className="div my-3">
            <p className="hired-designation">February 2021 (6 Days)</p>
          </div>
          <hr className="line" />

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <div className="space-between d-flex my-2">
            <p className="text">23 Feb, 2021</p>
            <button className="toggle-btn">
              <span className="text mr-2">8 Hours</span>
              <img src="./images/toggle-down.png" alt="" />
            </button>
          </div>

          <div className="div w-100 sticky-bottom pb-2 bg-white">
            <button
              className="theme-btn w-100 btn-block"
              onClick={props.closePopup}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSheet;
