import React from "react";
import { Link } from "react-router-dom";

const OngoingJob = () => {
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
        <Link to="" className=" link ">
          <div className="applied-box">
            <div className="row">
              <div className="col-4 px-0 job-logo2 flex-center relative">
                <div className="row flex-center px-2 mt-1 job-deployer">
                  <div className="col-2 px-0">
                    <img src="./images/user1.png" />
                  </div>
                  <div className="col-10 pl-2">
                    <p
                      className="match-score text-white"
                      style={{ fontSize: "8px", lineHeight: "10px" }}
                    >
                      Juzzi
                    </p>
                    <p
                      className="match-score text-bold text-white"
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        lineHeight: "10px",
                      }}
                    >
                      Zefort
                    </p>
                  </div>
                </div>
                {/* <img
                  src="./images/building1.png"
                  alt=""
                  className="vaccancy-pic img-fluid"
                /> */}
                <div className="blur-forview"></div>
                <div className="bottom-progress-bar">
                  <hr className="blue-line-bottom" />
                  <hr className="jobseeker-jobstatus" />
                </div>
              </div>
              <div className="col-8 pr-0 mt-1">
                <div className="space-between d-flex">
                  <div>
                    <h2 className="card-heding relative">Plumber(Job A1245)</h2>
                    <p className="job-requirement ">
                      Required Plumbers Immediately
                    </p>
                  </div>

                  <p className="">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="row mt-1">
                  <div className="col-5 px-0">
                    <p className="card-subHeading">Hired Date</p>
                    <p className="card-subHeading">Job Duration</p>
                    <p className="card-subHeading">Hours Worked</p>
                  </div>
                  <div className="col-7 px-0">
                    <p className="profile-info">01 Mar, 2021 </p>
                    <p className="profile-info">
                      01 Mar, 2021 - 03 April, 2021
                    </p>
                    <p className="profile-info"> 40 </p>
                  </div>
                </div>
              </div>

              <div className="space-between d-flex w-100 mt-3">
                <Link
                  to="/jobseeker-calender"
                  className="jobseeker-btn-black link ongoing-btn"
                >
                  Update Time Sheet
                </Link>
                <button className="jobseeker-btn ongoing-btn">End Job</button>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
        <Link to="" className=" link ">
          <div className="applied-box">
            <div className="row">
              <div className="col-4 px-0 job-logo2 flex-center relative">
                <div className="row flex-center px-2 mt-1 job-deployer">
                  <div className="col-2 px-0">
                    <img src="./images/user1.png" className="border-50" />
                  </div>
                  <div className="col-10 pl-2">
                    <p
                      className="match-score text-white"
                      style={{ fontSize: "8px", lineHeight: "10px" }}
                    >
                      Juzzi
                    </p>
                    <p
                      className="match-score text-bold text-white"
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        lineHeight: "10px",
                      }}
                    >
                      Zefort
                    </p>
                  </div>
                </div>
                {/* <img
                  src="./images/building1.png"
                  alt=""
                  className="vaccancy-pic img-fluid"
                /> */}
                <div className="blur-forview"></div>
                <div className="bottom-progress-bar">
                  <hr className="blue-line-bottom" />
                  <hr className="jobseeker-jobstatus" />
                </div>
              </div>
              <div className="col-8 pr-0 mt-1">
                <div className="space-between d-flex">
                  <div>
                    <h2 className="card-heding relative">Plumber(Job A1245)</h2>
                    <p className="job-requirement ">
                      Required Plumbers Immediately
                    </p>
                  </div>

                  <p className="">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="row mt-1">
                  <div className="col-5 px-0">
                    <p className="card-subHeading">Hired Date</p>
                    <p className="card-subHeading">Job Duration</p>
                    <p className="card-subHeading">Hours Worked</p>
                  </div>
                  <div className="col-7 px-0">
                    <p className="profile-info">01 Mar, 2021 </p>
                    <p className="profile-info">
                      01 Mar, 2021 - 03 April, 2021
                    </p>
                    <p className="profile-info"> 40 </p>
                  </div>
                </div>
              </div>

              <div className="space-between d-flex w-100 mt-3">
                <button className="jobseeker-btn-black ongoing-btn">
                  Update Time Sheet
                </button>
                <button className="jobseeker-btn  ongoing-btn">End Job</button>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default OngoingJob;
