import React from "react";
import { useNavigate } from "react-router-dom";

const PostSuccess = () => {
    const navigate = useNavigate();
  
    const navigateToSuccess = () =>{navigate('/post-vaccancy')}
  return (
    <div
      className="flex-center h-100"
      style={{ minHeight: "calc(100vh - 72px - 178px" }}
    >
      <div className="py-3">
        <img src="./images/post-success.jpg" style={{height: "300px", width: "300px"}} alt="" />
        <p className="post-success-text mt-3">Vacancy Posted Successfully !</p>

        <div className="space-between d-flex">
          <button
            className="theme-btn-outline mt-5 btn-block"
            onClick={() => navigateToSuccess()}
          >
            Edit Vacancy
          </button>

          <button
            className="theme-btn mt-5 btn-block"
            onClick={() => navigateToSuccess()}
          >
            Post Another Vacancy
          </button>
        </div>

        {/* <button
          className="theme-btn mt-5 btn-block"
          onClick={() => navigateToSuccess()}
        >
          Post Another Vacancy
        </button> */}
      </div>
    </div>
  );
};

export default PostSuccess;
