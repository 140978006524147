import React, { useState } from "react";
import BackBar from "../home/BackBar";
import RecommendedBox from '../home/component/RecommendedProfile'
const RecommendedProfile = () => {
  const [mapView, setMapView] = useState(false);
  const [listView, setListView] = useState(false);
  return (
    <>
      <div className="wrapped-content-area">
        <BackBar title="Plumber Applicants & Hired" />

        <h2 className="heading mx-4 useMyLocation-box">
          <img
            src="./images/location-indicater.png"
            alt=""
            className="mr-2"
          />
          <span className="location-main-text">Use my Current Location</span>
        </h2>

        <div className=" space-between d-flex mb-2 sticky-top bg-theme">
          <div className="d-flex ">
            <button
              onClick={() => {
                setMapView(true);
                setListView(false);
              }}
              className={mapView ? "login-active" : "login"}
            >
              Map View
            </button>
            <button
              onClick={() => {
                setMapView(false);
                setListView(true);
              }}
              className={mapView ? "login" : "login-active"}
            >
              List View
            </button>
          </div>
        </div>

        <div className="mt-3">
          <div className="row">
            <RecommendedBox />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendedProfile;
