import React from "react";

const JobseekerUpgradePlan = (props) => {
  return (
    <>
      <div className="blur-screen-full-area flex-center">
        <div className="jobseeker-upgrade-popup">
          <div className="row">
            <div className=" col-6 px-0">
              <p className="text">Upto</p>
              <p className="upgrade-heading mt-2">12 x More Visibility</p>
              <p className="text mt-2">
                Lorem ipsum dolorit consecteturadipiscing elit ut aliquam, purs
                s amet luctus venenatis, lectus magna fringilla.
              </p>
            </div>

            <div className=" col-6 px-0">
              <img className="" src="./images/growth.png" />
            </div>

            <button className="jobseeker-btn mt-3 py-1 btn-block" onClick={props.closePopup}>Pay €30</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobseekerUpgradePlan;
