import React from "react";
import BackBar from "../home/BackBar";
import HireBox from "./HireBox";

const Calendar = () => {
  return (
    <>
      <BackBar title="Hiring Details" />
      <div className="flex-center">
        <HireBox />
      </div>
      <p className="my-2 popup-heading">Vaccancy Details</p>
      <select
        id="cars"
        className="input-select w-25 ml-2 mb-2"
        placeholder="Select Job Category *"
      >
        <option value="0">Select Job Category *</option>
        <option value="1">Plumber</option>
        <option value="2">Technician</option>
        <option value="3">Carpainter</option>
      </select>

      <div className="my-2">
        <p className="heading">
          {" "}
          <img
            src="./images/lesthen.png"
            className="ml-2 mr-4"
            alt=""
          /> March{" "}
          <img src="./images/greaterthen.png" alt="" className="ml-4" />{" "}
        </p>
      </div>
      <div className="calendarViewMob my-3">
        <div className="row" style={{ padding: "0px" }}>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Sunday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Monday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Tuesday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Wednesday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Thursday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Friday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Saturday</span>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              1
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              2
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              3
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              4
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              5
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              6
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              7
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              8
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              9
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              10
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              11
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              12
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              13
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              14
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              15
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              16
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              17
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              18
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              19
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              20
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              21
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              22
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              23
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              24
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              25
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              26
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              27
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              28
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              29
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              30
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar active"
            style={{ flexDirecton: "column" }}
          >
            <p
              className="calendarDate"
              style={{ color: "rgb(95, 72, 138)", textAlign: "center" }}
            >
              31
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
        </div>
      </div>
      <div className="flex-center">
        <button className="theme-btn px-5 my-3">Submit</button>
      </div>
    </>
  );
};

export default Calendar;
