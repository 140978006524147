import React from "react";

const UnlockedProfileBox = (props) => {
  return (
    <>
      <div className="w-100">
        <div className="recommended-profile">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-3 px-0 relative flex-center">
              <img src="./images/blackBoy.jpg" alt="" className=" img-fluid w-100" />
              <p className="hire-status">Selected</p>
              <div className="row r-p-row flex-center">
                <div className="col-4">
                  <img
                    src="./images/call-reciever.png"
                    className="profile-btn"
                    title="Call"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    src="./images/email.png"
                    className="profile-btn"
                    title="Mail"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    src="./images/msg-profile.png"
                    className="profile-btn"
                    title="Message"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 pr-0 mt-md-3 mt-xm-3">
              <h2 className="card-heding relative">
                Rahul Dewal
                <img src="./images/verified.png" className="ml-2" alt="" />
                <span className="verified">Verified</span>
                <img
                  src="./images/send-job.png"
                  alt=""
                  className="addprofile"
                  onClick={props.addList}
                />
              </h2>
              <p className="designation">Frontend Developer</p>
              <p className="profile-availbility">(Available 8hr/Day)</p>
              <div className="row mt-2 align-items-center">
                <div className="col-5 px-0">
                  <p className="card-subHeading">Total Experience</p>
                  <p className="card-subHeading">Current Location</p>
                  <p className="card-subHeading">Package</p>
                </div>
                <div className="col-7 pl-0">
                  <p className="profile-info">1.5 Years </p>
                  <p className="profile-info">500m Away</p>
                  <p className="profile-info"> $700-$1000/m </p>
                </div>
                <div className="col-4 px-0">
                  <p className="horizontal-center mt-2">
                    <img
                      src="./images/match-badge.png"
                      className="img-size18"
                      alt=""
                    />
                    <span className="ms-1 match-profile">99% Match</span>
                  </p>
                </div>
                <div className="col-8 pl-0 d-flex justify-content-end">
                  <div className="unlocked-status">
                    <p className="unlocked-time">
                      Unlocked on 21/03/2021, 01:00 pm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      
    </>
  );
};

export default UnlockedProfileBox;
