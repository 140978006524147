import React from "react";

const Notification = (props) => {
  return (
    <>
      <div className="notification">
        <div className="space-between mb-4 d-flex">
          <p className="text">Notificarion</p>
          <button onClick={props.crossClose} className="text btn-none view-all">Close</button>
        </div>
        <div className="row candidates-notification">
          <div className="col-2 px-0 reative">
            <img src="./images/msg1.png" className="msg-profile mr-0 " alt="" />
            <img
              src="./images/msg1.png"
              className="msg-profile mr-0 second-notification-profile"
              alt=""
            />
            <img
              src="./images/msg1.png"
              className="msg-profile mr-0 third-notification-profile"
              alt=""
            />
          </div>
          <div className="col-8 pr-0 pb-2">
            <p className="text">
              HemaChandra, and 7 more candidates are found relevant ...
            </p>
            <button className="view-notification">View Candidates</button>
          </div>
          <div
            className="col-2 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className="text notification-time-status">Today</p>
            <p className="">
              {" "}
              <img src="./images/verticalDots.png" alt="" />{" "}
            </p>
          </div>
        </div>
        <hr className="line" />
        <div className="row msg-notification">
          <div className="col-2 px-0">
            <img src="./images/msg-noti.png" className="mr-0" alt="" />
          </div>
          <div className="col-8 pr-0 pb-2">
            <p className="text">
              HemaChandra, and 7 more candidates are found relevant ...
            </p>

            <button className="view-notification">View Message</button>
          </div>
          <div
            className="col-2 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className="text notification-time-status">Today</p>
            <p className="">
              {" "}
              <img src="./images/verticalDots.png" alt="" />{" "}
            </p>
          </div>
        </div>
        <hr className="line" />
        <div className="row candidates-notification">
          <div className="col-2 px-0 relative">
            <img src="./images/msg1.png" className="msg-profile mr-0 " alt="" />
            <img
              src="./images/msg1.png"
              className="msg-profile mr-0 second-notification-profile"
              alt=""
            />
            <img
              src="./images/msg1.png"
              className="msg-profile mr-0 third-notification-profile"
              alt=""
            />
          </div>
          <div className="col-8 pr-0 pb-2">
            <p className="text">
              HemaChandra, and 7 more candidates are found relevant ...
            </p>
            <button className="view-notification">View Candidates</button>
          </div>
          <div
            className="col-2 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className="text notification-time-status">Today</p>
            <p className="">
              {" "}
              <img src="./images/verticalDots.png" alt="" />{" "}
            </p>
          </div>
        </div>
        <hr className="line" />
        <div className="row msg-notification">
          <div className="col-2 px-0">
            <img src="./images/msg-noti.png" className="mr-0" alt="" />
          </div>
          <div className="col-8 pr-0 pb-2">
            <p className="text">
              HemaChandra, and 7 more candidates are found relevant ...
            </p>

            <button className="view-notification">View Message</button>
          </div>
          <div
            className="col-2 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className="text notification-time-status">Today</p>
            <p className="">
              {" "}
              <img src="./images/verticalDots.png" alt="" />{" "}
            </p>
          </div>
        </div>
        <hr className="line" />
        <div className="row candidates-notification">
          <div className="col-2 px-0 relative">
            <img src="./images/msg1.png" className="msg-profile mr-0 " alt="" />
            <img
              src="./images/msg1.png"
              className="msg-profile mr-0 second-notification-profile"
              alt=""
            />
            <img
              src="./images/msg1.png"
              className="msg-profile mr-0 third-notification-profile"
              alt=""
            />
          </div>
          <div className="col-8 pr-0 pb-2">
            <p className="text">
              HemaChandra, and 7 more candidates are found relevant ...
            </p>
            <button className="view-notification">View Candidates</button>
          </div>
          <div
            className="col-2 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className="text notification-time-status">Today</p>
            <p className="">
              {" "}
              <img src="./images/verticalDots.png" alt="" />{" "}
            </p>
          </div>
        </div>
        <hr className="line" />
        <div className="row msg-notification">
          <div className="col-2 px-0">
            <img src="./images/msg-noti.png" className="mr-0" alt="" />
          </div>
          <div className="col-8 pr-0 pb-2">
            <p className="text">
              HemaChandra, and 7 more candidates are found relevant ...
            </p>

            <button className="view-notification">View Message</button>
          </div>
          <div
            className="col-2 px-0"
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className="text notification-time-status">Today</p>
            <p className="">
              {" "}
              <img src="./images/verticalDots.png" alt="" />{" "}
            </p>
          </div>
        </div>
        <hr className="line" />
        <div className="link">View All</div>
      </div>
    </>
  );
};

export default Notification;
