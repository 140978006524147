import React from "react";

const Matching = (props) => {
  return (
    <div className="filter-collapse" style={{width: props.widthhh}}>
      <div className="d-flex space-between">
          <p className="toggle-text">Less Than Equal to 70%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>

      <div className="d-flex space-between">
          <p className="toggle-text">71% - 75%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>

      <div className="d-flex space-between">
          <p className="toggle-text">76% - 80%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>

      <div className="d-flex space-between">
          <p className="toggle-text">81% - 85%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>

      <div className="d-flex space-between">
          <p className="toggle-text">86% - 90%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>

      <div className="d-flex space-between">
          <p className="toggle-text">91% - 95%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>

      <div className="d-flex space-between">
          <p className="toggle-text">96% - 100%</p>
          <div className="input-checkbox">
              <input type="checkbox" name="" id="" />
          </div>
      </div>
      
    </div>
  );
};

export default Matching;
