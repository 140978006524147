const profileData = [
  {
    id: 1,
    profile_pic: "./images/profile3.png",
    candidate_name: "Steve Rogers",
    designation: "Technician",
    available_hours: "20hr/week",
    total_experience: "2 Years",
    curr_location: "2KM away",
    package: "$200 - $300 /M",
    profile_match_percentage: "99%",
    last_seen_status: "1hr ago",
  },
  {
    id: 2,
    profile_pic: "./images/profile4.png",
    candidate_name: "Donald Burno",
    designation: "Technician",
    available_hours: "20hr/week",
    total_experience: "2 Years",
    curr_location: "2KM away",
    package: "$400 - $500 /M",
    profile_match_percentage: "98%",
    last_seen_status: "2hr ago",
  },
  {
    id: 3,
    profile_pic: "./images/profile3.png",
    candidate_name: "Shepherd Luis",
    designation: "Technician",
    available_hours: "20hr/week",
    total_experience: "2 Years",
    curr_location: "2KM away",
    package: "$400 - $500 /M",
    profile_match_percentage: "98%",
    last_seen_status: "2hr ago",
  },
  {
    id: 4,
    profile_pic: "./images/profile4.png",
    candidate_name: "Ramesh D",
    designation: "Technician",
    available_hours: "20hr/week",
    total_experience: "2 Years",
    curr_location: "2KM away",
    package: "$400 - $500 /M",
    profile_match_percentage: "98%",
    last_seen_status: "2hr ago",
    
  },
  
];


export const location_of_candidates = [
  {
    distance: 500,
    available_candidates: 100,
  },
  {
    distance: 500,
    available_candidates: 100,
  },
  {
    distance: 500,
    available_candidates: 100,
  },
  {
    distance: 500,
    available_candidates: 100,
  },
  {
    distance: 500,
    available_candidates: 100,
  },
  {
    distance: 500,
    available_candidates: 100,
  },{
    distance: 500,
    available_candidates: 100,
  },
  {
    distance: 500,
    available_candidates: 100,
  },
];

export default profileData;
