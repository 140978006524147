import React, { useState } from "react";



const Search = (props) => {
  const [experience, setExperience] = useState(12);

  return (
    <>
      <div className="blur-screen-full-area flex-center" onClick={props.outsideTouch}>
        <div className="search-events">
          <div className="search-filters">
            <h2 className="heading mb-3 text-center">Search Candidates</h2>
            <div className="search-container flex-center relative ml-3 mb-2">
              <input
                type="text"
                placeholder="Search by Candidates/categories/Services"
                className="search-input"
                style={{ border: "1px solid grey" }}
              />
              <img src="./images/search.png" alt="search" />
            </div>

            <p className="popup-heading">Looking</p>

            <div className="looking-keys my-3">
              <button className="jobseeker-btn ">Plumber</button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Painter
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Carpainter
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Driver
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Technican
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Nurse
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Construction
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Electrician
              </button>
            </div>

            <select name="" id="" className="select-input w-100 mb-3">
              <option value="Plumbing">Select Required Skills *</option>
              <option value="Plumbing">Plumbing</option>
              <option value="Plumbing">Painting</option>
              <option value="Plumbing">Building Plumbering</option>
            </select>

            <p className="popup-heading">Employment Type</p>

            <div className="looking-keys my-3">
              <button className="jobseeker-btn" style={{ fontSize: "12px" }}>
                Part Time
              </button>
              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Full Time
              </button>

              <button
                className="jobseeker-tag deactive-tags"
                style={{ fontSize: "12px" }}
              >
                Contract
              </button>
            </div>

            <p className="popup-heading">Gender</p>


            <div className="range-box mb-3">
              <div className="space-between d-flex">
                <p className="popup-heading">Experience</p>
                <p className="range-details"> {experience}-5 Years</p>
              </div>
              <input
                type="range"
                name="experience"
                id="experience"
                min="0" max="5"
                value={experience}
                step="1"
                className="w-100 jobseeker-range mt-3"
                onChange={(e)=>setExperience(e.target.value)}
              />
            </div>
            <div className="range-box mb-3">
              <div className="space-between d-flex">
                <p className="popup-heading">Maximum Distance</p>
                <p className="range-details"> 40 KM</p>
              </div>
              <input
                type="range"
                name=""
                id=""
                className="w-100 jobseeker-range mt-3"
              />
            </div>
            <div className="range-box mb-3">
              <div className="space-between d-flex">
                <p className="popup-heading">Age</p>
                <p className="range-details"> 22-25 Years</p>
              </div>
              <input
                type="range"
                name=""
                id=""
                className="w-100 jobseeker-range mt-3"
              />
            </div>
            <div className="range-box mb-3">
              <div className="space-between d-flex">
                <p className="popup-heading">Salary</p>
                <p className="range-details"> 25k -30k</p>
              </div>
              <input
                type="range"
                name=""
                id=""
                className="w-100  jobseeker-range mt-3"
              />
            </div>
            <p className="popup-heading">Candidate Type</p>

            <div className="d-flex horizontal-center my-2">
              <input
                type="checkbox"
                style={{ color: "#96989A" }}
                className="mr-1"
                name=""
                id=""
              />
              <p className="text mr-4" style={{ color: "#96989A" }}>
                Free

              </p>

              <input
                type="checkbox"
                className="mr-1"
                name=""
                id=""
                style={{ color: "#96989A" }}
              />

              <p className="text mr-4" style={{ color: "#96989A" }}>
                Verified
              </p>
              <input
                type="checkbox"
                className="mr-1"
                name=""
                id=""
                style={{ color: "#96989A" }}
              />

              <p className="text mr-4" style={{ color: "#96989A" }}>
                Premium
              </p>
            </div>
            <hr className="line" />

            <button
              className="jobseeker-btn btn-block"
              onClick={props.closePopup}
            >
              Serch Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
