import React from "react";
import { useNavigate } from "react-router-dom";

const JobseekerLogin = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex-center">
        <div className="login-container">
          <img src="./images/jobseeker-login.png" alt="" className="m-auto" />
          <p className="text text-center">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Accusantium soluta asperiores tempore incidunt, nisi impedit?
          </p>
          <div className="">
            <div className="input-container my-2">
              <input
                className="input-text w-100 pl-5"
                placeholder="Enter Mobile Number"
                type="text"
                name=""
                id=""
              />
              <img src="./images/call.png" alt="" className="left-img" />
            </div>
            <div className="input-container my-2 mt-3">
              <input
                className="input-text w-100 pl-5"
                placeholder="Enter Password"
                type="password"
                name=""
                id=""
              />
              <img src="./images/lock.png" alt="" className="left-img" />
            </div>
          </div>

          <button
            className="jobseeker-btn mt-1"
            onClick={() => navigate("/jobseeker-home")}
          >
            Login
          </button>

          <p
            className="text-center link mt-2"
            onClick={() => {
              props.setActiveSection(2);
            }}
          >
            Forget Password
          </p>
        </div>
      </div>
    </>
  );
};

export default JobseekerLogin;
