import React, { useState } from "react";
import { Link } from "react-router-dom";
import CreditCoins from "../pages/home/CreditCoins";
import Notification from "./Notification/Notification";
import Search from "./Popup/Search";
import JobseekerProfile from "./Popup/JobseekerProfile";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./jobseeker.css";

const Header = () => {
  const [profile, setProfile] = useState(false);
  const [notification, setNotification] = useState(false);
  const [creditCoins, setCreditCoins] = useState(false);
  const [searchEvent, setSearchEvent] = useState(false);
  // const wrapperRef = useRef(null);
  { console.log(searchEvent, "lok") }
  return (
    <>
      <Navbar
        bg="dark"
        className="recruiter-header-container navbar-dark sticky-top"
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand
            href="#"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            Logo
          </Navbar.Brand>

          <Form className="d-flex">
            <div
              className="search-container flex-center relative"
              onClick={() => {
                setSearchEvent(!searchEvent);
              }}
            >
              <input
                type="text"
                placeholder="Search by Candidates/categories/Services"
                className="search-input form-control"
              />
              <img src="./images/search.png" alt="search" />
            </div>

          </Form>
          {searchEvent ? (
            <Search
              closePopup={() => {
                setSearchEvent(!searchEvent);
              }}
              outsideTouch={() => setSearchEvent(false)}
            />
          ) : null}

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 ml-auto"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link to="/jobseeker-home" className="header-links nav-link ml-0">
                <img
                  className="mfooter-icons"
                  src="./images/trending-up.png"
                  alt=""
                />
                <p className="text-white menu-label">Home</p>
              </Link>
              {/* <Nav.Link href="#action2">Link</Nav.Link> */}

              <Link to="/jobseeker-myJob" className="header-links nav-link">
                {/* <div className=""> */}
                <img
                  className="mfooter-icons"
                  src="./images/Vector.png"
                  alt=""
                  style={{ width: "30px" }}
                />
                {/* </div> */}
                <p className="text-white menu-label">My Jobs</p>
              </Link>

              <Link to="/jobseeker-message" className="nav-link header-links">
                {/* <p className="text header-heading">Messages  </p> */}
                <img
                  src="./images/message.png"
                  className="mfooter-icons"
                  alt="msg"
                />
                <p className="text-white menu-label">Message</p>
              </Link>
              <Nav.Link className="header-links">
                <div className="flex-center relative">
                  <button
                    className="btn-none flex-center header-links mr-0 relative"
                    onClick={() => {
                      setNotification(!notification);
                    }}
                  >
                    {/* <p className="text header-heading">Notification  </p> */}
                    <img
                      src="./images/bell.png"
                      className="mfooter-icons"
                      style={{ paddingRight: "4px" }}
                      alt="msg"
                    />
                    <p className="text-white menu-label ">Notifications</p>
                  </button>
                  {notification ? (
                    <Notification
                      crossClose={() => {
                        setNotification(!notification);
                      }}
                    />
                  ) : null}
                </div>
              </Nav.Link>
              <Nav.Link className="header-links">
                <div className="flex-center relative">
                  <div
                    className="header-links mr-0"
                    onClick={() => {
                      setProfile(!profile);
                    }}
                  >
                    {/* <div className=""> */}
                    <img
                      className="mfooter-icons"
                      src="./images/my-profile-icon.png"
                      alt=""
                    />
                    <p className="text-white menu-label">My Profile</p>
                    {/* </div> */}
                  </div>
                  {profile ? (
                    <JobseekerProfile
                      closePopup={() => {
                        setProfile(!profile);
                      }}
                    />
                  ) : null}
                  {creditCoins ? (
                    <CreditCoins
                      crossClose={() => {
                        setCreditCoins(!creditCoins);
                      }}
                      clickAnywhere={true}
                    />
                  ) : null}
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
