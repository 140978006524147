import React from "react";
import { useNavigate } from "react-router-dom";
const NoVaccancy = () => {

  const navigate = useNavigate();
  
  const navigateToSuccess = () =>{
    navigate('/post-vaccancy');
  }


  return (
    <div className="flex-center h-100 " style={{minHeight:"calc(100vh - 72px - 178px"}}>
      <div className="text-center">
        <img src="./images/no-data.png" alt="" />

        <h4 className="mt-3 opps">Opps !</h4>
        <p className="text mt-2">No Data Found !</p>

        <button className="theme-btn mt-5 btn-block" onClick={() => navigateToSuccess()}>Post  New Vacancy</button>
      </div>
    </div>
  );
};

export default NoVaccancy;
