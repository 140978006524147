import React from "react";
import BackBar from "../home/BackBar";
// import RecommendedProfile from "../home/component/RecommendedProfile";
// import Filters from "./Filters";
// import HireBox from "./HireBox";
import VaccancyBox from "./VaccancyBox";
// import TimeSheet from "./TimeSheet";

const Vaccancy = () => {
  // const [active, setActive] = useState(true);
  // const [hires, setHires] = useState(false);
  // const [filters, setFilters] = useState(false);
  // const [timeSheet, setTimeSheet] = useState(false);
  return (
    <>
      {/* <Header /> */}
      <div className="wrapped-content-area">
        <BackBar title="My Vaccancies" />
        <div className="container-fluid">
          {/* <div
            className="search-container flex-center relative"
            style={{ margin: "auto", marginTop: "10px" }}
          >
            <input
              type="text"
              placeholder="Search by Candidates/categories/Services"
              className="search-input"
            />
            <img src="./images/search.png" alt="search" />
          </div>
          <hr className="line mt-2 mb-0" /> */}
          <div className="my-3">
            <div className="row ">
              {/* <div className="mb-2 flex-center sticky-top bg-theme">
                  <p className="login-active">Vacancies</p>
                </div> */}

              {/* <hr className="line mt-n2 mb-3" /> */}
              <VaccancyBox cols="col-lg-4 col-md-6 col-sm-12" />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Vaccancy;
