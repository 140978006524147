import React, { useState } from "react";
import AppliedJobs from "./AppliedJobs";
import OngoingJob from "./OngoingJob";
import UnlockedRecruiter from './UnlockedRecruiter'

const Myob = () => {
  const [active, setActive] = useState(2);

  return (
    <>
      <div className=" space-between d-flex mb-2 sticky-top job-option-bar px-3 align-items-center">
        <div className="d-flex my-3">
          <button
            onClick={() => {
              setActive(1);
              //   setHires(false);
            }}
            className={active === 1 ? "option-active" : "unactive"}
          >
            Ongoing Jobs
          </button>
          <button
            onClick={() => {
              setActive(2);
              //   setHires(true);
            }}
            className={active === 2 ? "option-active" : "unactive"}
          >
            Unlocked By Recruiter
          </button>

          <button
            onClick={() => {
              setActive(3);
              //   setHires(true);
            }}
            className={active === 3 ? "option-active" : "unactive"}
          >
            Applied Jobs
          </button>
        </div>
      </div>


      

      <div className="row mt-3">
      {active ===1 ? <OngoingJob/>: null}
      {active ===2 ? <UnlockedRecruiter/>: null}
      {active ===3 ? <AppliedJobs/> : null}
        
      </div>
    </>
  );
};

export default Myob;
