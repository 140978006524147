import React from "react";
import { useNavigate } from "react-router-dom";
const Successfully = () => {

  const navigate = useNavigate();
  
  const navigateToSuccess = () =>{
    navigate('/home');
  }

  return (
    <div className="flex-center h-100" style={{minHeight:"calc(100vh - 72px - 178px"}}>
      <div className="py-3">
        <img src="./images/successfully.png" alt="" />
        <p className="text mt-3">
          Your Hire Request to ramesh has been sent Successfully
        </p>

        <button className="theme-btn mt-5 btn-block" onClick={() => navigateToSuccess()}>Resume Hiring</button>
      </div>
    </div>
  );
};

export default Successfully;
