import React, { useState } from "react";
import { Link } from "react-router-dom";
import JobseekerUpgradePlan from "./Jobseeker-UpgradePlan";

const JobseekerProfile = (props) => {
  const [upgrade, setUpgrade] = useState(false);
  const profileProgress = "35%";
  return (
    <div className="my-profile-container relative">
      <Link to="/jobseeker-profile" className="btn-none close-popup-btn" onClick={props.closePopup}>
        <img className="" src="./images/pencilBlack.png" alt />
      </Link>
      <div className="my-profile ">
        <div className="users-info">
          <div className="row">
            <div className="col-3 px-0 ">
              <img src="./images/msg1.png" className="my-profile-user" alt="" />
            </div>
            <div className="col pr-0">
              <h3 className="user-name">Bruce Banner </h3>
              <p className="text">+1 555 555 345</p>
              <p className="text">brucebnr@gmail.com</p>
            </div>
          </div>
          {/* <p className="myProfile-heading mt-3 mb-2">Company Name</p> */}
          <div className="space-between w-75 d-flex">
            <p className="text">
              <img
                src="./images/mail.png"
                alt=""
                style={{ height: "12px", width: "17px" }}
                className="mr-2"
              />
              bruce.banr@abc.com
            </p>
            <span className="verify">Verify</span>
          </div>
          <div className="space-between w-75 d-flex">
            <p className="text">
              <img
                src="./images/call-outline.png"
                alt=""
                style={{ height: "14px", width: "13px" }}
                className="mr-2"
              />
              +1 555 555 345
            </p>
            <span className="verify">Verify</span>
          </div>
          <p className="myProfile-heading my-2"> About Company</p>
          <p className="text">
            Lorem ipsum dolor sit amet, consectadipiscing elit, sed do eiusmsit
            at,consectetur adipiscing eliem Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusm... View More
          </p>
          <div className="space-between d-flex mt-3">
            <p className="completed-status">35% Profile is Completed</p>
            <p className="updated-status">Updated 2 Weeks ago</p>
          </div>
          <div className="profile-proggress-bar">
            <div
              className="proggress-bar"
              style={{ width: profileProgress }}
            ></div>
          </div>
          <button
            className="jobseeker-btn btn-block mt-3"
            onClick={() => {
              setUpgrade(true);
            }}
          >
            Upgrade Plan
          </button>
          {upgrade ? (
            <JobseekerUpgradePlan
              closePopup={() => {
                setUpgrade(false);
              }}
            />
          ) : null}
        </div>

        {/* <div className="loginFirst">
            <div className="flex-center flex-column">
                <h2 className="heading">Welcome To Catch-Up</h2>
                <Link to="/jobseeker-login" className="mt-4 link jobseeker-btn btn-block w-75">Login</Link>

                <Link to="/jobeeker-login" className="link mt-4">
                    <p className="text active-link">Create An Account</p>
                </Link>
            </div>
        </div> */}

        <hr />
        <div className="">
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/subcription-bag.png" alt="" />
                </div>
              </div>
              <div className="col">
                <Link to="/subcription" className="text">
                  My Subcriptions
                </Link>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/list.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">My Lists</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/vaccancy-black.png" alt="" />
                </div>
              </div>
              <div className="col">
                <Link to="/jobseeker-myJob" className="text">
                  My Jobs
                </Link>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />
          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/unlock-profile.png" alt="" />
                </div>
              </div>
              <div className="col">
                <Link to="/jobseeker-myJob" className="text">
                  Unlocked by Recruiter
                </Link>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/settings.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Settings</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/bell-black.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Alerts/Notifications</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/guard.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Privacy & Policies</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="line" />

          <div className="profiles-box">
            <div className="row horizontal-center">
              <div className="col-2 px-0">
                <div
                  className="p-2 jobseeker-bg-light flex-center"
                  style={{ borderRadius: "10px" }}
                >
                  <img src="./images/contact.png" alt="" />
                </div>
              </div>
              <div className="col">
                <p className="text">Contact Support</p>
              </div>
              <div className="col-1 px-0 justify-content-end d-flex h-100">
                <img src="./images/go-arrow.png" alt="" />
              </div>
            </div>
          </div>
          <div className=" text-center mt-3">
            <button className="jobseeker-btn-outline px-5">Log Out</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobseekerProfile;
