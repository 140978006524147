import React, { useState } from "react";
import Experience from "./toggles/Experience";
import Matching from "./toggles/Matching";
import Package from "./toggles/Package";
import PassoutYear from "./toggles/PassoutYear";
import Location from "./toggles/Location";

const Filters = (props) => {
  const [filterBy, setFilterBy] = useState(0);
  const [transformRot, setTransform] = useState("0deg");
  const [widthh, setWidth] = useState("0%")

  return (
    <>
      <div className="blur-screen flex-center">
        <div className="filter-box py-0">
          <div className="space-between d-flex sticky-top bg-white m-0">
            <p className="filter-active">Filter By</p>
            <p className="filter">Sort By</p>
          </div>
          <hr className="line mt-2" />

          <div className="space-between d-flex my-3">
            <p className="toggle-text">Experience</p>
            <button
              className="toggle-btn toggle-img-btn"
              onClick={() => {
                setFilterBy(filterBy === 1 ? 0 : 1);
                setTransform(transformRot ==="180deg" ? "0deg" : "180deg");
                
              }}
              style={{transform : "rotate("+ transformRot+")"}}
            >

              {console.log(widthh)}
              <img
                src="./images/toggle-down.png"
                className="toggle-img"
                alt=""
              />
            </button>
          </div>
          {filterBy === 1 ? <Experience widthhh={"100%"}/> : null}

          <hr className="line" />

          <div className="space-between d-flex mb-3">
            <p className="toggle-text">Location Radius</p>
            <button
              className="toggle-btn toggle-img-btn"
              onClick={() => {
                setFilterBy(filterBy === 2 ? 0 : 2);
                setTransform(transformRot ==="180deg" ? "0deg" : "180deg");
                
              }}
              style={{transform : "rotate("+ transformRot+")"}}
            >

              {console.log(widthh)}
              <img
                src="./images/toggle-down.png"
                className="toggle-img"
                alt=""
              />
            </button>
          </div>
          {filterBy === 2 ? <Location widthhh={"100%"}/> : null}

          <hr className="line" />
          <div className="space-between d-flex mb-3">
            <p className="toggle-text">Matching Percentage</p>

            <input type="checkbox" id="btnControl" />
            <button
              className="toggle-btn toggle-img-btn"
              onClick={() => {
                setFilterBy(filterBy === 3 ? 0 : 3);
                setTransform(transformRot ==="180deg" ? "0deg" : "180deg");
                
              }}
              style={{transform : "rotate("+ transformRot+")"}}
            >

              {console.log(widthh)}
              <img
                src="./images/toggle-down.png"
                className="toggle-img"
                alt=""
              />
            </button>
          </div>
          {filterBy === 3 ? <Matching widthhh={"100%"} /> : null}

          <hr className="line" />
          <div className="space-between d-flex mb-3">
            <p className="toggle-text">Package Range</p>
            <button
              className="toggle-btn toggle-img-btn"
              onClick={() => {
                setFilterBy(filterBy === 4 ? 0 : 4);
                setTransform(transformRot ==="180deg" ? "0deg" : "180deg");
                
              }}
              style={{transform : "rotate("+ transformRot+")"}}
            >

              {console.log(widthh)}
              <img
                src="./images/toggle-down.png"
                className="toggle-img"
                alt=""
              />
            </button>
          </div>
          {filterBy === 4 ? <Package widthhh={"100%"}/> : null}

          <hr className="line" />
          <div className="space-between d-flex mb-3">
            <p className="toggle-text">Passout Year</p>
            <button
              className="toggle-btn toggle-img-btn"
              onClick={() => {
                setFilterBy(filterBy === 5 ? 0 : 5);
                setTransform(transformRot ==="180deg" ? "0deg" : "180deg");
                
              }}
              style={{transform : "rotate("+ transformRot+")"}}
            >

              {console.log(widthh)}
              <img
                src="./images/toggle-down.png"
                className="toggle-img"
                alt=""
              />
            </button>
          </div>
          {filterBy === 5 ? <PassoutYear widthhh={"100%"}/> : null}

          <div className="space-between d-flex bg-white sticky-bottom mb-n2">
            <button className="theme-btn">Clear All</button>
            <button className="theme-btn" onClick={props.closePopup} >Apply</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
