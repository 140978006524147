import React from "react";
import BackBar from "../home/BackBar";

const Subcription = () => {
  return (
    <>
      <div className="wrapped-content-area">
        <BackBar title="Upgrade Subcription" />
        <div className="container-fluid">
          <div className="row subcription-section mt-3 p-0">
            <div className="col-4 col-lg-6"></div>
            <div className="col-8 col-lg-6 px-0">
              <div className="d-flex justify-content-around">
                <div className="free-subs">
                  <p className="text">Free</p>
                </div>
                <div className="vip-subs">
                  <div className="vip" style={{width: "unset"}}>VIP</div>
                </div>
                <div className="premium-subs">
                  <div className="premium-box">Premium</div>
                </div>
              </div>
            </div>
          </div>
          <hr className="divider" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="row align-items-center">
            <div className="col-4 col-lg-6">
              {" "}
              <div className="text">Feature-2</div>
            </div>

            <div className="col-8 col-lg-6">
              <div className="d-flex justify-content-around">
                <img src="./images/grey-cross.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
                <img src="./images/blue-tick.png" alt="" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-1" />

          <div className="d-flex mt-3">
            <div className="plan mr-3">
              <p className="text">Current Plan</p>
              <p className="vip mt-2">VIP</p>
              <p className="heading mt-3">€ 300/m</p>
            </div>
            <div className="plan">
              <p className="text">Upgrade To</p>
              <p className="premium-plan mt-2">Premium</p>
              <p className="heading mt-3">€ 499/m</p>
            </div>
          </div>

          <div className="flex-center my-3">
            <button className="theme-btn px-5">Upgrade Now</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subcription;
