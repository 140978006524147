import React from 'react'

const Experience = (props) => {
    return (
        <div className="filter-collapse" style={{ width: props.widthhh }}>
            <div className="d-flex space-between">
                <p className="toggle-text">Less Than Equal to 1 Year</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">2 - 3 years </p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">4 - 5 Years</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">6 - 7 Years</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">8 - 9 Years</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">10 - 12 Years</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

            <div className="d-flex space-between">
                <p className="toggle-text">13+ Years</p>
                <div className="input-checkbox">
                    <input type="checkbox" name="" id="" />
                </div>
            </div>

        </div>
    );
}

export default Experience