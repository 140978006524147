import React from "react";
import { Link } from "react-router-dom";

const SearchResult = (props) => {
  return (
    <>
      <div className="blur-screen-full-area flex-center flex-column">
        <div className="details">
          <div
            className=" mx-auto"
            style={{
              backgroundImage: "url('./images/Rectangle-scenery.png')",
              width: "33%",
              backgroundSize: "cover",
              borderRadius: "14px",
              minWidth: "320px",
            }}
          >
            <div className="applicants-details-container">
              <div className="row">
                <div className="col-10 pl-0">
                  <div className="row">
                    <div className="col-3 px-0 mb-3 flex-center relative">
                      <img src="./images/msg1.png" className="" alt="" />
                      <p className="premium">Premium</p>
                    </div>
                    <div className="col-9 px-0">
                      <p className="candidate-name">Rony</p>
                      <p className="designation text-bold text-white">
                        Piping Co
                        <span className="transparent-border-btn ml-3">
                          
                          Urgent Vacancy
                        </span>
                      </p>
                    </div>
                  </div>

                  <p className="heading h5 mt-2 text-white">
                    Plumbers needed for Construction Site on Regular Basis
                  </p>

                  <p className="text-white text">
                    We Lorem ipsum dolor sit amet, consectadipiscing elit, sed
                    do eiusmsit at,consectetur adipiscing eliem Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit, sed do
                    eiusm.eiusmsit at,consectetur adipiscing eliem Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit
                  </p>
                  <p className="sub-heading text-white font-12 mt-2">
                    Experience
                  </p>
                  <p className="text-white text">
                    5+ Years of Experience required
                  </p>
                  <p className="sub-heading text-white font-12 mt-2">
                    Employment Type
                  </p>
                  <p className="text-white text">
                    Full Time - Part Time
                    <span className="text-warning text-bold">
                      (Available 20 Hrs/week)
                    </span>
                  </p>
                  <p className="sub-heading text-white font-12 mt-2">Salary</p>
                  <p className="text-white text">€ 300/ pm</p>

                  <p className="sub-heading text-white font-12 mt-2">
                    Current Location
                  </p>
                  <p className="text-white text">INDIA</p>

                  <p className="sub-heading text-white font-12 mt-2">
                    About Compny
                  </p>
                  <p className="text-white text">
                    Lorem ipsum dolor sit amet, consectadipiscing elit, sed do
                    eiusmsit at,consectetur adipiscing eliem Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do eiusm
                  </p>

                  <p className="sub-heading text-white font-12 mt-2">Skills</p>
                  <div className="d-block">
                    <span className="transparent-border-btn">
                      Bootstrap 4/ Bootsrap 5
                    </span>
                    <span className="transparent-border-btn">CSS</span>
                    {/* <span className="transparent-border-btn">ReactJs</span>
                <span className="transparent-border-btn">JavaScrpt</span> */}
                  </div>
                  <p className="sub-heading text-white font-12 mt-2">
                    Education
                  </p>
                  <div>
                    <span className="transparent-border-btn">B.Sc Science</span>
                    <span className="transparent-border-btn">B.Sc Science</span>
                  </div>

                  <p className="sub-heading text-white font-12 mt-2">
                    Passout Year
                  </p>
                  <div>
                    <span className="transparent-border-btn">2017</span>
                  </div>
                  <p className="sub-heading text-white font-12 mt-2">
                    Licenses
                  </p>
                  <div>
                    <span className="transparent-border-btn">
                      License Name 1
                    </span>
                    <span className="transparent-border-btn">
                      License Name 2
                    </span>
                  </div>

                  <div className="mt-4">
                    <span className="transparent-border-btn px-3">
                      
                      <img
                        src="./images/lock-white.png"
                        className="mr-2"
                        alt=""
                      />
                      Contact Details Locked
                    </span>
                  </div>

                  <button className="jobseeker-btn mt-3">
                    See Full Details
                  </button>
                </div>
                <div className="col-2 px-0">
                  <div className=" flex-column sticky-top">
                    <div className="white-circle flex-center flex-column">
                      <p className="font-12">99%</p>
                      <p className="font-8">Match</p>
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/send-job.png" alt="" />
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/share-icon.png" alt="" />
                    </div>

                    <div className="jobseeker-circle flex-center">
                      <img src="./images/unlcoked-profile-white.png" alt="" />
                    </div>
                    <div
                      className="red-circle flex-center"
                      onClick={props.closePopup}
                    >
                      <img src="./images/cross.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="applicants-distance">
                <p className="distance-text">
                  
                  <img
                    src="./images/location.png"
                    className=" img-size16"
                    alt=""
                  />
                  3Km Away
                </p>
              </div>
            </div>
            <div className="applicants-availability bg-white pb-3 m-0 w-100">
              <div className="row">
                <div className="col-10">
                  <p className="sub-heading font-12 mt-2">Skills</p>
                  <div className="d-block">
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>
                      Bootstrap 4/ Bootsrap 5
                    </span>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>CSS</span>
                    {/* <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>ReactJs</span>
                <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>JavaScrpt</span> */}
                  </div>
                  <p className="sub-heading font-12 mt-2">
                    Education
                  </p>
                  <div>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>B.Sc Science</span>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>B.Sc Science</span>
                  </div>

                  <p className="sub-heading font-12 mt-2">
                    Passout Year
                  </p>
                  <div>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>2017</span>
                  </div>
                  <p className="sub-heading font-12 mt-2">
                    Licenses
                  </p>
                  <div>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>
                      License Name 1
                    </span>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>
                      License Name 2
                    </span>
                  </div>

                  <p className="sub-heading font-12 mt-2">
                  No. of Vacancies
                  </p>
                  <div>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>5 Vacancies</span>
                  </div>

                  <p className="sub-heading font-12 mt-2">
                  Job Duration
                  </p>
                  <div>
                    <span className="transparent-border-btn text-dark" style={{border: "1px solid #444"}}>02/03/2021 - 03/04/2021</span>
                  </div>
                </div>

                <div className="col-2 px-0">
                  <div className=" flex-column sticky-top">
                    <div className="white-circle flex-center flex-column">
                      <p className="font-12">99%</p>
                      <p className="font-8">Match</p>
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/send-job.png" alt="" />
                    </div>
                    <div className="white-circle flex-center">
                      <img src="./images/share-icon.png" alt="" />
                    </div>

                    <div className="jobseeker-circle flex-center">
                      <img src="./images/unlcoked-profile-white.png" alt="" />
                    </div>
                    <div
                      className="red-circle flex-center"
                      onClick={props.closePopup}
                    >
                      <img src="./images/cross.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResult;
