const ongoingJobData = [
    {
      id: 1,
      profile_pic: "./images/smallPlumber.png",
      jobTitle: "Plumbers (Job A1245)",
      company: "Piping Company",
      description: "Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...",
      durationDate: "02/03/2021 - 05/04/2021",
      jobStatus: "60%"
    },{
      id: 2,
      profile_pic: "./images/smallPlumber.png",
      jobTitle: "Electrician (Job A1245)",
      company: "Piping Company",
      description: "Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...",
      durationDate: "02/03/2021 - 05/04/2021",
      jobStatus: "80%"
    },{
      id: 3,
      profile_pic: "./images/smallPlumber.png",
      jobTitle: "Doctor (Job A1245)",
      company: "Piping Company",
      description: "Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...",
      durationDate: "02/03/2021 - 05/04/2021",
      jobStatus: "40%"
    }
    ,{
      id: 4,
      profile_pic: "./images/smallPlumber.png",
      jobTitle: "Carpainter (Job A1245)",
      company: "Construction Company",
      description: "Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...",
      durationDate: "02/03/2021 - 05/04/2021",
      jobStatus: "30%"
    },
]
export default ongoingJobData;