import React, { useState } from "react";

const ApplicantsAvailability = (props) => {
  const [slot, setSlot] = useState("");
  return (
    <div className="applicants-availability">
      <div className="row">
        <div className="col-10">
          <p className="popup-heading my-3">Availability for Part Time </p>

          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Monday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "mon" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "mon" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>

          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Tuesday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "tue" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "tue" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>

          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Wednesday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "wed" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "wed" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>
          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Thursday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "thu" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "thu" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>

          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Friday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "fri" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "fri" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>

          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Saturday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "sat" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "sat" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>

          <div className="available-box ">
            <div className="space-between d-flex">
              <p className="available-day">Sunday</p>
              <p className="availavle-slots">
                (2 slots Available){" "}
                <img
                  src="./images/toggle-down.png"
                  style={{cursor: "pointer"}}
                  onClick={() => setSlot(slot === "" ? "sun" : "")}
                  className="ml-2"
                />{" "}
              </p>
            </div>
            {slot === "sun" ? (
              <>
                <p className="text font-weight-light">
                  All times are in your local time (PST)
                </p>

                <div className="mt-2 d-flex flex-column animation">
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                  <span className="slot-time-box">11.00AM - 01:00PM</span>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="col-2 flex-column pl-0">
          <div className=" flex-column sticky-top pt-3">
            <div className="white-circle flex-center flex-column">
              <p className="font-12">99%</p>
              <p className="font-8">Match</p>
            </div>
            <div className="white-circle flex-center">
              <img src="./images/bookmark.png" alt="" />
            </div>
            <div className="white-circle flex-center">
              <img src="./images/send-job.png" alt="" />
            </div>
            <div className="white-circle flex-center">
              <img src="./images/share-icon.png" alt="" />
            </div>

            <div className="blue-circle flex-center">
              <img src="./images/unlcoked-profile-white.png" alt="" />
            </div>
            <div className="red-circle flex-center" onClick={props.closePopup}>
              <img src="./images/cross.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsAvailability;
