import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccessPopup = () => {
  const navigate = useNavigate();

  const navigateToSuccess = () => {
    navigate("/post-vaccancy");
  };
  const navigateToExplore = () => {
    navigate("/home");
  };

  return (
    <div className="flex-center" style={{height: "70vh"}}>
      <div className="text-center my-3 payment-success-box flex-center flex-column">
        <img src="./images/payment-successfull.png" alt="" />

        <h4 className="mt-3 opps">Thank you !</h4>
        <p className="text mt-2">Your Payment was successful.</p>

        <p className="text" style={{ fontSize: "10px" }}>
          Your Transcation ID: 1236589742 & Your plan Renews on 15/04/2021
        </p>

        <div className="space-between d-flex w-100">
          <button
            className="theme-btn-outline mt-4 "
            onClick={() => navigateToSuccess()}
          >
            Post Vacancy
          </button>

          <button
            className="theme-btn mt-4"
            onClick={() => navigateToExplore()}
          >
            Explore Candidates
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPopup;
