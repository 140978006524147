import React from "react";
import ongoingJobData from "../Jobseeker/Api/ongoingJobData";

const OngoingJobs = () => {
  return (
    <>
      {ongoingJobData.map((item, index) => {
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0" key={item.id}>
            <div className="unlocked-profile w-100">
              <div className="row">
                <div className="col-3 pl-0 flex-center">
                  <div className="profile-container">
                    <img
                      className="job-profile-img"
                      src={item.profile_pic}
                    />
                  </div>
                </div>
                <div className="col-9 px-0">
                  <p className="job-name">{item.jobTitle}</p>
                  <p className="job-for">{item.company}</p>
                </div>
              </div>
              <p className="card-subheading mt-1">Description</p>
              <p className="card-answer">
                {item.description}
              </p>
              <p className="card-subheading mt-1">
                Duration:{" "}
                <span className="card-answer ml-2">
                  {item.durationDate}
                </span>
              </p>

              <p className="card-status-title mt-1">Job Status</p>
              <div className="my-2">
                <hr class="blue-line-bottom" />
                <hr class="jobseeker-jobstatus" style={{width: item.jobStatus}}></hr>
              </div>
              <div className="space-between w-100 d-flex my-4">
                <button className="jobseeker-btn-black">Time Sheet</button>

                <button className="jobseeker-btn">End Job</button>
              </div>
            </div>
          </div>
        );
      })}

      {/* <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="row">
            <div className="col-3 pl-0 flex-center">
              <div className="profile-container">
                <img
                  className="job-profile-img"
                  src=" ./images/smallPlumber.png"
                />
              </div>
            </div>
            <div className="col-9 px-0">
              <p className="job-name">Plumbers (Job A1245)</p>
              <p className="job-for">Piping Company</p>
            </div>
          </div>
          <p className="card-subheading mt-1">Description</p>
          <p className="card-answer">
            Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...{" "}
          </p>
          <p className="card-subheading mt-1">
            Duration:{" "}
            <span className="card-answer ml-2">02/03/2021 - 05/04/2021</span>
          </p>

          <p className="card-status-title mt-1">Job Status</p>
          <div className="my-2">
            <hr class="blue-line-bottom" />
            <hr class="jobseeker-jobstatus"></hr>
          </div>
          <div className="space-between w-100 d-flex my-4">
            <button className="jobseeker-btn-black">Time Sheet</button>

            <button className="jobseeker-btn">End Job</button>
          </div>
        </div>
      </div> */}

      {/* <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="row">
            <div className="col-3 pl-0 flex-center">
              <div className="profile-container">
                <img
                  className="job-profile-img"
                  src=" ./images/smallPlumber.png"
                />
              </div>
            </div>
            <div className="col-9 px-0">
              <p className="job-name">Plumbers (Job A1245)</p>
              <p className="job-for">Piping Company</p>
            </div>
          </div>
          <p className="card-subheading mt-1">Description</p>
          <p className="card-answer">
            Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...{" "}
          </p>
          <p className="card-subheading mt-1">
            Duration:{" "}
            <span className="card-answer ml-2">02/03/2021 - 05/04/2021</span>
          </p>

          <p className="card-status-title mt-1">Job Status</p>
          <div className="my-2">
            <hr class="blue-line-bottom" />
            <hr class="jobseeker-jobstatus"></hr>
          </div>
          <div className="space-between w-100 d-flex my-4">
            <button className="jobseeker-btn-black">Time Sheet</button>

            <button className="jobseeker-btn">End Job</button>
          </div>
        </div>
      </div>



      <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="row">
            <div className="col-3 pl-0 flex-center">
              <div className="profile-container">
                <img
                  className="job-profile-img"
                  src=" ./images/smallPlumber.png"
                />
              </div>
            </div>
            <div className="col-9 px-0">
              <p className="job-name">Plumbers (Job A1245)</p>
              <p className="job-for">Piping Company</p>
            </div>
          </div>
          <p className="card-subheading mt-1">Description</p>
          <p className="card-answer">
            Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...{" "}
          </p>
          <p className="card-subheading mt-1">
            Duration:{" "}
            <span className="card-answer ml-2">02/03/2021 - 05/04/2021</span>
          </p>

          <p className="card-status-title mt-1">Job Status</p>
          <div className="my-2">
            <hr class="blue-line-bottom" />
            <hr class="jobseeker-jobstatus"></hr>
          </div>
          <div className="space-between w-100 d-flex my-4">
            <button className="jobseeker-btn-black">Time Sheet</button>

            <button className="jobseeker-btn">End Job</button>
          </div>
        </div>
      </div>


      <div className="col-lg-3 col-md-4 col-sm-6 flex-center px-0">
        <div className="unlocked-profile w-100">
          <div className="row">
            <div className="col-3 pl-0 flex-center">
              <div className="profile-container">
                <img
                  className="job-profile-img"
                  src=" ./images/smallPlumber.png"
                />
              </div>
            </div>
            <div className="col-9 px-0">
              <p className="job-name">Plumbers (Job A1245)</p>
              <p className="job-for">Piping Company</p>
            </div>
          </div>
          <p className="card-subheading mt-1">Description</p>
          <p className="card-answer">
            Lorem ipsum dolor sit amet, consectadipiscing e sed do eiusm...{" "}
          </p>
          <p className="card-subheading mt-1">
            Duration:{" "}
            <span className="card-answer ml-2">02/03/2021 - 05/04/2021</span>
          </p>

          <p className="card-status-title mt-1">Job Status</p>
          <div className="my-2">
            <hr class="blue-line-bottom" />
            <hr class="jobseeker-jobstatus"></hr>
          </div>
          <div className="space-between w-100 d-flex my-4">
            <button className="jobseeker-btn-black">Time Sheet</button>

            <button className="jobseeker-btn">End Job</button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default OngoingJobs;
