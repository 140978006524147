import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginUrl } from "../../Service/service";
import { postData } from "../../Service/ApiMethod";

const Login = (props) => {


  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  //   const navigateToSuccess = () => {
  //     navigate("/home");
  // };
  const navigate = useNavigate();
  const navigateToLogin = () => { navigate("/home"); }

  let objItem = {
    phone: phone,
    password: password,
    user_types: "recruiter"
  };

  console.log("static", objItem);
  const handleLogin = async () => {
    await postData(objItem, loginUrl)
      .then(res => res.json())

      .then(data => {
        try {

          console.log("From Api", data);
          const token = data?.data?.access;
          localStorage.setItem(
            "access token login", token,
            "refresh token", data?.data?.refresh,
            'login_Phone', data?.data?.user?.phone
          );
          if (token !== undefined) {
            navigateToLogin();
          } else {
            alert(data?.error_message)
          }
        } catch (err) {
          console.log('error hgappended', err)
          setError(err.message, 'email already exists');
          alert("Error", err)
        }
      })

  }





  // const handleLogin = async () => {
  //   try {
  //     let result = await fetch(loginUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Accept": "*/*",
  //       },
  //       body: JSON.stringify({ phone, password })

  //     });
  //     result = await result.json();
  //     localStorage.setItem("user-info", JSON.stringify(result));
  //     navigate("/home")
  //     console.log("Result :==", result);

  //   }
  //   catch (err) {
  //     console.log(err);
  //   }

  // }

  // useEffect(() => {
  //   axios
  //     .get(baseUrl + "/recruiter/JobCategories/listt/")
  //     .then((res) => {
  //       setJobCategories(res.data.data);
  //       console.log(res.data.data);
  //     })
  //     .catch((err) => console.log(err.message));
  // }, [])

  return (
    <>

      <img src="./images/password.png" alt="" className="login-img" />
      <p className="text text-center">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium
        soluta asperiores tempore incidunt, nisi impedit?
      </p>
      <div className="">
        <div className="input-container my-2">
          <input
            className="input-text w-100 pl-5"
            placeholder="Enter Mobile Number"
            type="text"
            onChange={(e) => { setPhone(e.target.value) }}
            name=""
            id="phone"
          />
          <img src="./images/call.png" alt="" className="left-img" />
        </div>
        <div className="input-container my-2 mt-3">
          <input
            className="input-text w-100 pl-5"
            placeholder="Enter Password"
            type="password"
            onChange={(e) => { setPassword(e.target.value) }}
            name=""
            id="pwd"
          />
          <img src="./images/lock.png" alt="" className="left-img" />
        </div>
      </div>

      <button className="theme-btn mt-1" onClick={handleLogin}>Login</button>
      <div className="d-flex space-between w-75 m-auto mt-2">
        <p
          className="text-center link mt-2 text-underline"
          onClick={() => {
            props.setActiveSection(2)
          }}
        >
          Forget Password
        </p>
        <Link to="/registration" className="text-center link mt-2 text-underline">Create an Account</Link>
      </div>

    </>
  );
};

export default Login;
