import React, {useState} from "react";

const AddToList = (props) => {


  return (
    <>
      <div className="blur-screen-full-area flex-center">
        <div className="add-to-list-box">
          <div className="text-center">
            <p className="headig mt-2">Add to a List</p>
          </div>
          <div className="div my-3">
              <p className="text"> 
              <img src="./images/send-job.png" className="mr-2" alt="" />Create New List</p>
          </div>

          <div className="space-between d-flex my-2">
            <p className="text">Plumbers For Society</p>
            <button className="toggle-btn">
              <img src="./images/plus.png" alt="" />
            </button>
          </div>

          <hr className="line" />

          <div className="space-between d-flex mb-2">
            <p className="text">Technicians For Flats</p>
            <button className="toggle-btn">
              <img src="./images/plus.png" alt="" />
            </button>
          </div>

          <hr className="line" />
          <div className="space-between d-flex mb-2">
            <p className="text">Driver for My Manager</p>

            <input type="checkbox" id="btnControl" />
            <button
              className="toggle-btn toggle-img-btn">
              <img
                src="./images/plus.png"
                className="toggle-img"
                alt=""
              />
            </button>
          </div>

          <hr className="line" />
          <div className="space-between d-flex mb-2">
            <p className="text">Nurses for Aditya Birla Hospital</p>
            <button className="toggle-btn">
              <img src="./images/plus.png" alt="" />
            </button>
          </div>

          <hr className="line" />
          <div className="space-between d-flex mb-2">
            <p className="text">Passout Year</p>
            <button className="toggle-btn">
              <img src="./images/plus.png" alt="" />
            </button>
          </div>

            <button className="theme-btn w-100" onClick={props.closePopup}>Done</button>

        </div>
      </div>
    </>
  );
};

export default AddToList;
