import React, { useState } from "react";

const Availbility = (props) => {
  const [slot, setSlot] = useState("");
  return (
    <>
      <div className="">
        <p className="popup-heading my-3">Availability for Part Time </p>

        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Monday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "mon" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "mon" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>

        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Tuesday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "tue" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "tue" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>

        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Wednesday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "wed" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "wed" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>
        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Thursday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "thu" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "thu" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>

        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Friday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "fri" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "fri" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>

        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Saturday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "sat" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "sat" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>

        <div className="available-box mb-2">
          <div className="space-between d-flex">
            <p className="available-day">Sunday</p>
            <p className="availavle-slots">
              (2 slots Available){" "}
              <img
                src="./images/toggle-down.png"
                onClick={() => setSlot(slot === "" ? "sun" : "")}
                className="ml-2"
              />{" "}
            </p>
          </div>
          {slot === "sun" ? (
            <>
              <p className="text font-weight-light">
                All times are in your local time (PST)
              </p>
              <div className="mt-2">
                <div className="row mb-2 justify-content-center">
                  <div className="col-9 px-0">
                    <div className="space-between d-flex">
                      <p className="min-heading">Time Slot</p>
                      <p className="text">11.30 am -2.00 pm</p>
                    </div>

                    <input type="range" className="jobseeker-range my-2 w-100" />
                  </div>
                  <div className="col-3  pr-0 align-items-center d-flex">
                    <button className="jobseeker-btn">Add</button>
                  </div>
                </div>

                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
                <span className="slot-time-box">11.00AM - 01:00PM</span>
              </div>
            </>
          ) : null}
        </div>

        <button className="mt-3 btn-block jobseeker-btn">Submit</button>
      </div>
    </>
  );
};

export default Availbility;
