import React, { useRef, useEffect, useState } from "react";

const CreditTopup = (props) => {
  const wrapperRef = useRef(null);
  const [credit, setCredit] = useState(120);
  const pay = Number(credit) / Number(10);
  // const [r, setR] = useState();

  useOutsideAlerter(wrapperRef);
  

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        console.log("jhajsh")
        if (ref.current && !ref.current.contains(event.target)) {
          props.clickAnywhere(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="blur-screen-full-area flex-center">
      <div className="flex-center flex-column coins-topup"  ref={wrapperRef}>
        <h2 className="popup-heading">Recharge Credit</h2>
        <p className="text">
          Lorem ipsum dolorit consecteturadipiscing elit ut aliquam, purs s amet
          luctus venenatis, lectus magna fringilla.
        </p>

        <div className="topup-range-box my-3">
          <div className="space-between d-flex">
            <p className="text">Select No. of Credits</p>
            <p className="text">{Number(credit)} credits</p>
          </div>

          <input
            type="range"
            name="credit" id="credit" className="w-100 mt-3"
            min="20"
            max="500"
            value={credit}
            onChange={(e) => { setCredit(e.target.value); e.preventDefault(); }}
          />
        </div>

        <button className="theme-btn mt-3 btn-block" value={pay} onClick={props.closePopup}>
          Pay $ {pay}
        </button>
      </div>
    </div>
  );
};

export default CreditTopup;
