import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchResult from "../Popup/SearchResult";

const Calendar = (props) => {
  const [searchView, setSearchView] = useState(false)
  return (
    <>
      {/* Ongoing Job Box */}
      <div className="flex-center">
        <div className="applied-box applied-box-onTimesheet my-2">
          <div className="row">
            <div className="col-4 px-0 job-logo2 flex-center relative">
              <div className="row flex-center px-2 mt-1 job-deployer">
                <div className="col-2 px-0">
                  <img src="./images/user1.png" />
                </div>
                <div className="col-10 pl-2">
                  <p
                    className="match-score text-white"
                    style={{ fontSize: "8px", lineHeight: "10px" }}
                  >
                    Juzzi
                  </p>
                  <p
                    className="match-score text-bold text-white"
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      lineHeight: "10px",
                    }}
                  >
                    Zefort
                  </p>
                </div>
              </div>
              {/* <img
                  src="./images/building1.png"
                  alt=""
                  className="vaccancy-pic img-fluid"
                /> */}
              <div className="blur-forview"></div>
              <div className="bottom-progress-bar">
                <hr className="blue-line-bottom" />
                <hr className="jobseeker-jobstatus" />
              </div>
            </div>
            <div className="col-8 pr-0 mt-1">
              <div className="space-between d-flex">
                <div>
                  <h2 className="card-heding relative">Plumber(Job A1245)</h2>
                  <p className="job-requirement ">
                    Required Plumbers Immediately
                  </p>
                </div>

                <p className="">
                  <img
                    src="./images/match-badge.png"
                    className="img-size18"
                    alt=""
                  />
                  <span className="ms-1 match-profile">99% Match</span>
                </p>
              </div>
              <div className="row mt-1">
                <div className="col-5 px-0">
                  <p className="card-subHeading">Hired Date</p>
                  <p className="card-subHeading">Job Duration</p>
                  <p className="card-subHeading">Hours Worked</p>
                </div>
                <div className="col-7 px-0">
                  <p className="profile-info">01 Mar, 2021 </p>
                  <p className="profile-info">01 Mar, 2021 - 03 April, 2021</p>
                  <p className="profile-info"> 40 </p>
                </div>
              </div>
            </div>

            <div className="space-between d-flex w-100 mt-3">
              <button
                to="/jobseeker-calender"
                className="jobseeker-btn-black link ongoing-btn"
                onClick={()=>{setSearchView(true)}}
              >
                View Job Details
              </button>
              <button className="jobseeker-btn ongoing-btn ">
                End Job
              </button>
            </div>
            {searchView ? <SearchResult closePopup={()=>{setSearchView(false)}}/> : null}
          </div>
        </div>
      </div>
      {/* //Ongoing Job Box */}

      <div className="m-2">
        <p className="heading">
          {" "}
          <img
            src="./images/lesthen.png"
            className="ml-2 mr-4"
            alt=""
          /> March{" "}
          <img src="./images/greaterthen.png" alt="" className="ml-4" />{" "}
        </p>
      </div>
      <div className="calendarViewMob m-3">
        <div className="row" style={{ padding: "0px" }}>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Sunday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Monday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Tuesday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Wednesday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Thursday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Friday</span>
          </div>
          <div className="col calendarHeaderDiv" style={{ padding: "0px" }}>
            <span className="days">Saturday</span>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              1
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              2
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              3
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              4
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              5
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              6
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              7
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              8
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              9
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              10
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              11
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              12
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              13
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              14
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              15
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              16
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              17
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              18
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              19
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              20
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              21
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              22
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              23
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              24
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              25
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              26
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              27
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              28
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              29
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}>
              30
            </p>
          </div>
        </div>
        <div className="row" style={{ margin: "0px" }}>
          <div
            className="col dateBoxCalendar active"
            style={{ flexDirecton: "column" }}
          >
            <p
              className="calendarDate"
              style={{ color: "rgb(95, 72, 138)", textAlign: "center" }}
            >
              31
            </p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
          <div
            className="col dateBoxCalendar"
            style={{ flexDirecton: "column" }}
          >
            <p className="calendarDate" style={{ textAlign: "center" }}></p>
          </div>
        </div>
      </div>
      <div className="w-50 m-3 m-auto">
        <div className="space-between d-flex">
          <div className="check-in-box">
            <p className="mini-heading">Check in Time :</p>
            <div className="d-flex mt-3">
              <input type="text" placeholder="Hrs" className="time-input" />
              <input
                type="text"
                placeholder="Mins"
                className="time-input"
              ></input>
            </div>
            <p className="mini-text">24 Hours</p>
          </div>

          <div className="check-out-box">
            <p className="mini-heading">Check Out Time :</p>
            <div className="d-flex mt-3">
              <input type="text" placeholder="Hrs" className="time-input" />
              <input
                type="text"
                placeholder="Mins"
                className="time-input"
              ></input>
            </div>
            <p className="mini-text">24 Hours</p>
          </div>
        </div>
        <textarea
          name=""
          id=""
          className="input-text pl-3 mb-2"
          rows={4}
          placeholder="Task Details"
          style={{ height: "120px" }}
        ></textarea>
        <div className="d-flex horizontal-center">
          <input type="checkbox" className="mr-2" /> <p className="text">End Job</p>
        </div>
        <div className="flex-center">
          <button className="jobseeker-btn px-5 my-3">Update Time Sheet</button>
        </div>
      </div>
    </>
  );
};

export default Calendar;
