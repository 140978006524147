import React, { useState, useEffect } from "react";
import Nearby from "./component/Nearby";
import RecommendedProfile from "./component/RecommendedProfile";
import UnlockedProfiles from "./component/UnlockedProfiles";
import { Link } from "react-router-dom";
import ApplicantsDetails from "../Vaccancy/ApplicantsDetails";
import Location from "./component/Location";
import axios from "axios";
import { baseUrl, job_category_url } from "../../Service/service";

const Home = () => {
  const [popup, setPopup] = useState(false);
  const [location, setLocation] = useState(false);

  // JobCategories

  const [JobCategories, setJobCategories] = useState();
  const [isError, setIsError] = useState();
  const [showMore, setShowMore] = useState({
    jobCategoryBox: 9,
    title: "Show More"
  });

  useEffect(() => {
    fetchJobCat()
  }, [])

  const fetchJobCat = async () => {
    try {
      const res = await axios.get(baseUrl + job_category_url);
      console.log(res?.data?.data);
      setJobCategories(res.data);
    }
    catch (err) {
      console.log(" code Error", err);
      setIsError(err.message);
      console.log(isError);
    }

  }
  return (
    <>
      {/* <Header /> */}
      <div className="">
        {/* <div
          className="search-container flex-center relative"
          style={{ margin: "auto", marginTop: "10px" }}
        >
          <input
            type="text"
            placeholder="Search by Candidates/categories/Services"
            className="search-input"
          />
          <img src="./images/search.png" alt="search" />
        </div> */}

        <h1 className="heading mt-3 px-3">Job Categories</h1>
        {
          console.log(JobCategories?.data.length, "cpoll")}
        <div className="row space-between">
          {(isError != null ?
            <>
              <div className="col-lg-3 col-md-4 col-sm-6 col">
                <div className="categories-box">
                  <img
                    src="./images/err.jpg"
                    alt="img"
                    className="category-img"
                    style={{ width: "48px", height: "48px" }}
                  />
                  <h3 className="category-title text-danger">{isError}</h3>
                  <p className="numberOfCandidate text-warning">(Error code)</p>
                </div>
              </div>
            </>
            :

            JobCategories?.data.map((item, index) => {

              if (isError) {
                console.log("not equal 200");
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col" key={index}>
                    <div className="categories-box">
                      <img
                        src="./images/carpenter.png"
                        alt="img"
                        className="category-img"
                      />
                      <h3 className="category-title">{isError}</h3>
                      <p className="numberOfCandidate">(Error code)</p>
                    </div>
                  </div>
                )
              }
              else if (index <= showMore.jobCategoryBox) {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col" key={index}>
                    <div className="categories-box">
                      <img
                        src="./images/carpenter.png"
                        alt="img"
                        className="category-img"
                      />
                      <h3 className="category-title">{item?.category_name}</h3>
                      <p className="numberOfCandidate">(200 Candidates)</p>
                    </div>
                  </div>
                )
              }

            })
          )}



        </div>
        <div className="flex-center mt-3">
          {
            JobCategories?.data.length >= 10 ? <><button
              className="link btn-none view-all"
              onClick={() => {
                setShowMore({
                  jobCategoryBox: (showMore.jobCategoryBox === 9 ? JobCategories?.data.length : 9),
                  title: (showMore.title === "Show More" ? "Show Less" : "Show More"),
                })
              }}>
              {showMore.title}
              <span className="transform"> Less </span>
            </button></> : null
          }

          {/* <button className="link btn-none view-all" onClick={()=>setJobCategoryBox(jobCategoryBox == 8 ? JobCategories?.data.length : 8)}>Show More <span className="transform"> > </span> </button> */}
        </div>
        {/* Subscription */}
        <div className="flex-center mt-3 subcription-section">
          <div className="subcription-box">
            <div className="row">
              <div className="col-8 px-0">
                <h3 className="sub-heading mb-2">Your Subscription Info</h3>
                <p className="text mb-3">
                  Lorem ipsum dolor sit amet, consectadipi sed do eiusm sed do
                  eiusmo tem Lorem ipsum dolor.
                </p>
                <Link
                  to="/subcription"
                  className="theme-btn-link mt-3"
                  onClick={() => {
                    setPopup(true);
                  }}
                >
                  Upgrade your Subscription
                </Link>
              </div>
              <div className="col-4 px-0 flex-center">
                <img src="./images/upgrade-subcription.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Subscription */}

        {/* Recommended Profiles  section */}
        <div className="d-flex space-between mt-3 px-3">
          <h1 className="heading">Recommended Candidates</h1>
          <Link to="/recommended-profiles" className="view-all">
            View All
          </Link>
        </div>
        {/* Recommended Profiles  section */}

        <div className="row mt-3">
          <RecommendedProfile />
        </div>

        <div className="mt-3 flex-center complete-profile-section">
          <div
            className="profile-completion-box flex-center"
            style={{ flexDirection: "column" }}
          >
            <div className="completion-graph relative">
              <img src="./images/profile-graph.png" alt="" />
              <img
                src="./images/graph-arrow.png"
                alt=""
                className="graph-arrow"
              />
            </div>
            <h2 className="profile-heading">Complete Your Profile</h2>
            <div className="about-profile">
              Lorem ipsum dolor sit amet, consectadipi sed do eiusm sed do
              eiusmo tem Lorem ipsum dolor{" "}
            </div>
            <a href="/MainProfile" className="theme-btn-link mt-3">
              Complete Now
            </a>
          </div>
        </div>

        <div className="d-flex space-between mt-3 px-3">
          <h1 className="heading">Unlocked Candidates</h1>
          <a href="./unlock-profiles" className="view-all">
            All Profiles
          </a>
        </div>

        <div className="row mt-3">
          <UnlockedProfiles />
        </div>
        <div className="d-flex space-between mt-3 px-3">
          <div className="relative">
            <h1 className="heading">Candidates Near Me</h1>
            <p className="text">
              Lorem ipsum dolor sit amet, consectadipi sed do eiusm
            </p>
          </div>
          <button
            className="view-all btn-none"
            onClick={() => {
              setLocation(!location);
            }}
          >
            View All
          </button>
        </div>
        {location ? (
          <Location
            closePopup={() => {
              setLocation(!location);
            }}
            crossClose={() => {
              setLocation(!location);
            }}
          />
        ) : null}
        <div className="mt-3 row">
          <Nearby />
        </div>
        {popup ? (
          <ApplicantsDetails
            closePopup={() => {
              setPopup(false);
            }}
          />
        ) : null}
      </div>
      <div style={{ height: "72px" }} />
      {/* <Footer/> */}
    </>
  );
};

export default Home;
