import React, { useState, useEffect } from "react";
import BackBar from "../home/BackBar";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { degree_url, experiance_url, job_category_url, vaccancy_url, skills_url } from "../../Service/service";
import { getData, postDataAuth } from "../../Service/ApiMethod";

const PostForm = () => {

  const navigate = useNavigate();

  const [jobCategories, setJobCategories] = useState();
  const [skills, setSkills] = useState();
  const [experience, setExperience] = useState();
  const [degree, setDegree] = useState();
  const [error, setError] = useState();


  const [postVaccancy, setPostVaccancy] = useState({

    title: "",
    job_category: null,
    skills: null,
    job_description: "",
    experiance: null,
    urgent_vacancy: null,
    liscense_required: null,
    emp_details: "",
    no_of_vacancy: "",
    job_duration: "",
    compensation_details: "",
    compensation_amount: "",
    work_location: "",
    contact_person_name: "",
    contact_number: "",
    highest_degree: null,
    degree_completion_year: "",
    maximum_application_allowled: "",
    from_date: "",
    to_date: "",
    search_radious: ""
  })

  console.log("state Object ", postVaccancy);

  const navigateToSuccess = () => {
    navigate('/post-success');
  }

  const handlePostVaccancy = async (e) => {
    e.preventDefault();
    const objItem = {
      title: postVaccancy.title,
      job_category: parseInt(postVaccancy.job_category),
      skills: parseInt(postVaccancy.skills),
      job_description: postVaccancy.job_description,
      experiance: postVaccancy.experiance,
      urgent_vacancy: postVaccancy.urgent_vacancy,
      liscense_required: postVaccancy.liscense_required,
      emp_details: postVaccancy.emp_details,
      no_of_vacancy: postVaccancy.no_of_vacancy,
      job_duration: postVaccancy.job_duration,
      compensation_details: postVaccancy.compensation_details,
      compensation_amount: postVaccancy.compensation_amount,
      work_location: postVaccancy.work_location,
      contact_person_name: postVaccancy.contact_person_name,
      contact_number: postVaccancy.contact_number,
      highest_degree: postVaccancy.highest_degree,
      degree_completion_year: postVaccancy.degree_completion_year,
      maximum_application_allowled: postVaccancy.maximum_application_allowled,
      from_date: postVaccancy.from_date,
      to_date: postVaccancy.to_date,
      search_radious: postVaccancy.search_radious,
    }

    if(objItem.title === ""){
      alert("Title Required")
    }else if(postVaccancy.job_category === null){
      alert("Enter Job Category")
    }else if(postVaccancy.skills === null){
      alert("Enter skills")
    }else if(postVaccancy.experiance === null){
      alert("Enter experiance")
    }
    else if( objItem.job_description=== ""){
      alert("enter Job Description ")
    }else if (objItem.work_location === ""){
      alert("Please Enter Work Location")
    }
    
    else if( objItem.contact_person_name=== ""){
      alert("enter contact_person_name ")
    }else if (objItem.contact_number === ""){
      alert("Please Enter contact_number")
    }
    
    else if( objItem.compensation_details=== ""){
      alert("enter compensation_details ")
    }else if (objItem.compensation_amount === ""){
      alert("Please compensation_amount")
    }
    
    else if( objItem.emp_details=== ""){
      alert("enter emp_details ")
    }else if (objItem.no_of_vacancy === ""){
      alert("Please Enter no_of_vacancy")
    }
    
    else if( objItem.job_duration=== ""){
      alert("enter job_duration ")
    }else if (objItem.urgent_vacancy === ""){
      alert("Please Enter Work Location")
    }    
    else {
      await postDataAuth(objItem, vaccancy_url)
        .then(response => response.json())
        .then(data => {
          console.log(data, "vaccancy_url")


          if (data.message !== "") {
            console.log("from API ", data.error_message);
            // alert(data?.message);
          } else {
            console.log("'data.message' is not empthy");
          }
          try {
            if (data.error_message) {
              alert("Plese Fill All Fields")
            } else if (data.message) {
              alert(data.message);
              navigateToSuccess();
            }
          } catch (err) {
            console.log('error hgappended', err)
            setError(err.message, 'email already exists');
            alert("Error", error)
          }
        })
    }




  }


  const getJobCategories = () => {
    getData(job_category_url)
      .then((response) => response.json())
      .then((data) => {
        setJobCategories(data.data);
      })
      .catch((error) => {
        console.log("Error in categories from Post Form", error);
      });
  };
  const getSkills = () => {
    getData(skills_url)
      .then((response) => response.json())
      .then((data) => {
        setSkills(data.data);
      })
      .catch((error) => {
        console.log("Error in getSkills from Post Form", error);
      });
  };
  const getExperience = () => {
    getData(experiance_url)
      .then((response) => response.json())
      .then((data) => {
        setExperience(data.data);
      })
      .catch((error) => {
        console.log("Error in getExperience from Post Form", error);
      });
  };
  const getDegree = () => {
    getData(degree_url)
      .then((response) => response.json())
      .then((data) => {
        setDegree(data.data);
      })
      .catch((error) => {
        console.log("Error in getDegree from Post Form", error);
      });
  };
  useEffect(() => {
    getJobCategories();
    getSkills();
    getExperience();
    getDegree();
  }, [])

  return (
    <>
      <div className="wrapped-content-area">
        <BackBar title="Post Vaccancy" />
        <div className="container">
          <form action="" className="form-container my-3">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">

                <h5 className="mb-3 form-heading">Job Details</h5>
                <input
                  required
                  type="text"
                  placeholder="Enter Job Title *"
                  className="input-text w-100 mb-2"
                  onChange={(e) => {
                    let obj = { ...postVaccancy }
                    obj["title"] = e.target.value;
                    setPostVaccancy(obj)

                    // setPostVaccancy(prev => ({

                    //   ...prev.postVaccancy,
                    //   // ...prev,
                    //   title: e.target.value

                    // })
                    // )
                  }}
                  value={postVaccancy.title}
                />

                <select
                  id="jobCategory"
                  className="input-text w-100 mb-2"
                  placeholder="Select Job Category *"
                  // onChange={(e) => {
                  //   setPostVaccancy(prev => ({
                  //     postVaccancy: {
                  //       ...prev,
                  //       skills: e.target.value
                  //     }
                  //   })
                  //   )
                  // }}
                  onChange={(e) => {
                    let obj = { ...postVaccancy }
                    obj["job_category"] = e.target.value;
                    setPostVaccancy(obj)
                  }}
                  value={postVaccancy.job_category}
                >
                  <option value="0">Select Job Category *</option>
                  {
                    jobCategories?.map((item, index) => {
                      return (
                        <option value={index} id={item.id} key={index}
                        >{item?.category_name}</option>
                      )
                    })
                  }
                </select>
                <select
                  id="skills"
                  className="input-text w-100 mb-2"
                  placeholder="Select Required Skills *"
                  onChange={(e) => {
                    let obj = { ...postVaccancy }
                    obj["skills"] = e.target.value;
                    setPostVaccancy(obj)
                  }}
                  value={postVaccancy.skills}
                >
                  <option value="0">Select Required Skills *</option>
                  {
                    skills?.map((item, index) => {
                      return (
                        <option value={index} id={item.id} key={index}>{item.skill_name}</option>
                      )
                    })
                  }
                </select>
                <select
                  id="experience"
                  className="input-text w-100 mb-2"
                  placeholder="Select Required Experience *"
                  onChange={(e) => {
                    let obj = { ...postVaccancy }
                    obj["experiance"] = e.target.value;
                    setPostVaccancy(obj)
                  }}
                  value={postVaccancy.experiance}
                >
                  <option value="select">Select Required Experience *</option>
                  {
                    experience?.map((item, index) => {
                      return (
                        <option value={index} id={item.id} key={index} >{item.experience}</option>
                      )
                    })
                  }
                </select>
                <textarea
                  name=""
                  id="jobDescription"
                  cols=""
                  className="input-text w-100 mb-2"
                  rows="4"
                  placeholder="Job Description"
                  onChange={(e) => {
                    let obj = { ...postVaccancy }
                    obj["job_description"] = e.target.value;
                    setPostVaccancy(obj)
                  }}
                  value={postVaccancy.job_description}
                ></textarea>

                <h5 className="mb-3 form-heading">Company Details</h5>
                <div className="Employment-Details">
                  <div className=" relative">
                    <input
                      type="text"
                      placeholder="Work  Location *"
                      className="input-text w-100"
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["work_location"] = e.target.value;
                        setPostVaccancy(obj)
                      }}
                      value={postVaccancy.work_location}
                    />
                    <img src="./images/location_indicater.png" style={{ position: "absolute", right: "12px", top: "32%" }} alt="" />

                  </div>

                  <input
                    type="text"
                    placeholder="Contact Person Name *"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["contact_person_name"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.contact_person_name}

                  />

                  <input
                    type="tel"
                    placeholder="Contact Number *"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["contact_number"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    pattern="[0-9]{10}"
                    value={postVaccancy.contact_number}
                  />
                </div>
                <h5 className="my-2 form-heading">Compensation Details</h5>
                <div className="Employment-Details">
                  <div className="space-between horizontal-center d-flex w-75">
                    <div className="horizontal-center">
                      <input type="checkbox" className="mr-2" name="" id=""
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["compensation_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }}
                        value="Daily"
                      />
                      <span className="text">Daily</span>
                    </div>

                    <div className="horizontal-center">
                      <input type="checkbox" className="mr-2" name="" id=""
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["compensation_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }}
                        value="Weekly"
                      />
                      <span className="text">Weekly</span>
                    </div>

                    <div className="horizontal-center">
                      <input type="checkbox" className="mr-2" name="" id=""
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["compensation_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }}
                        value="Monthly"
                      />
                      <span className="text">Monthly</span>
                    </div>

                    <div className="horizontal-center mb-1">
                      <input type="checkbox" className="mr-2" name="" id=""
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["compensation_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }}
                        value="Yearly"
                      />
                      <span className="text">Yearly</span>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Compensation Amount"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["compensation_amount"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.compensation_amount}

                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5 className="mb-3 form-heading">Qualification Required</h5>
                <div className="Qualification-Details mt-3">
                  <select
                    id="degree"
                    className="input-text w-100 mb-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["highest_degree"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.highest_degree}


                  >
                    <option value="select">Select Highest Degree *</option>
                    {degree?.map((item, index) => {
                      return (
                        <option value={index} id={item.id} key={index}>{item.degree}</option>
                      )
                    })}
                  </select>

                  <select
                    id="complete_degree_year"
                    className="input-text w-100 mb-2"
                    placeholder="Select Job Category *"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["degree_completion_year"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.degree_completion_year}

                  >
                    <option value="0">Highest Degree Completion Year *</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                  </select>
                </div>
                <h5 className="my-3 form-heading">Employment Details</h5>
                <div className="Employment-Details mt-3">
                  <div className="space-between horizontal-center d-flex mt-2 w-75">
                    <div className="horizontal-center">
                      <input type="checkbox" className="mr-2" name="" value="Part Time"
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["emp_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }} id="" />
                      <span className="text">Part Time</span>
                    </div>

                    <div className="horizontal-center">
                      <input type="checkbox" className="mr-2" name="" value="Full Time"
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["emp_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }} id="" />
                      <span className="text">Full Time</span>
                    </div>

                    <div className="horizontal-center">
                      <input type="checkbox" className="mr-2" value="Contract"
                        onChange={(e) => {
                          let obj = { ...postVaccancy }
                          obj["emp_details"] = e.target.value;
                          setPostVaccancy(obj)
                        }} />
                      <span className="text">Contract</span>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="No of Vacancies *"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["no_of_vacancy"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.no_of_vacancy}
                  />

                  <input
                    type="text"
                    placeholder="Job Duration(in days) *"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["job_duration"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.job_duration}
                  />
                </div>

                <p className="text my-3">Is this vacancy Urgent ?</p>
                <div className="d-flex w-100">
                  <span className="w-25">
                    <input
                      type="radio"
                      name="urgent-vaccancy"
                      id="urgent-vaccancy-answer"
                      className="mr-2"
                      checked={postVaccancy.urgent_vacancy}
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["urgent_vacancy"] = true;
                        setPostVaccancy(obj)
                      }}
                      value={true}
                    />
                    <span className="text">Yes</span>
                  </span>
                  <span className="w-25">
                    <input
                      type="radio"
                      name="urgent-vaccancy"
                      id="urgent-vaccancy-answer"
                      className="mr-2"
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["urgent_vacancy"] = false;
                        setPostVaccancy(obj)
                      }}
                      value={false}
                    />
                    <span className="text">No</span>
                  </span>
                </div>
                <p className="text mt-2 mb-3">Is Liscense Required ?</p>
                <div className="d-flex w-100">
                  <span className="w-25">
                    <input
                      type="radio"
                      name="liscense"
                      id="liscense"
                      className="mr-2"
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["liscense_required"] = e.target.checked;
                        setPostVaccancy(obj)
                      }}
                      value={true}
                    />
                    <span className="text">Yes</span>
                  </span>
                  <span className="w-25">
                    <input
                      type="radio"
                      name="liscense"
                      id="liscense"
                      className="mr-2"
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["liscense_required"] = !postVaccancy.liscense_required;
                        setPostVaccancy(obj)
                      }}
                    />
                    <span className="text">No</span>
                  </span>
                </div>

                <h5 className="my-3 form-heading">Post Settings</h5>
                <div className="Qualification-Details mt-3">
                  <input
                    type="text"
                    placeholder="Maximum Applications Allowed"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["maximum_application_allowled"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.maximum_application_allowled}
                  />
                  <div className=" space-between d-flex">
                    <input
                      type="date"
                      className="input-date mt-2"
                      name=""
                      id=""
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["from_date"] = e.target.value;
                        setPostVaccancy(obj)
                      }}
                      value={postVaccancy.from_date}
                    />
                    <input
                      type="date"
                      className="input-date mt-2"
                      name=""
                      id=""
                      onChange={(e) => {
                        let obj = { ...postVaccancy }
                        obj["to_date"] = e.target.value;
                        setPostVaccancy(obj)
                      }}
                      value={postVaccancy.to_date}
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Search Radius (In meters)"
                    className="input-text w-100 mt-2"
                    onChange={(e) => {
                      let obj = { ...postVaccancy }
                      obj["search_radious"] = e.target.value;
                      setPostVaccancy(obj)
                    }}
                    value={postVaccancy.search_radious}
                  />
                </div>
              </div>

              <div className="flex-center mt-3 w-100">
                <button
                  type="submit"
                  onClick={(e) => handlePostVaccancy(e)}
                  className="theme-btn px-5 text-center"
                  style={{ height: "40px" }}
                >
                  Review & Post Vacancy
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </>
  );
};

export default PostForm;
