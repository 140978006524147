import React from "react";

const Location = (props) => {
  return (
    <div className="blur-screen-full-area flex-center">
      <div className="location-all pt-0">
        <div className="sticky-top bg-white pt-3">
          <div className="space-between d-flex mb-3">
            <h2 className="heading text-center">Candidates Near Me</h2>
            <button className="view-all btn-none" onClick={props.crossClose}>Close</button>
          </div>
          <div className="search-container w-100 flex-center relative">
            <input
              type="text"
              placeholder="Search by Candidates/categories/Services"
              className="search-input w-100"
              style={{ border: "1px solid grey" }}
            />
            <img src="./images/search.png" alt="search" />
          </div>
          <h2 className="heading my-3">
            <img src="./images/location-indicater.png" alt="" className="mr-2" />{" "}
            <span className="location-main-text">Use my Current Location</span>
          </h2>
          <hr className="line" />

          <p className="recent-location mb-3">Recent Location</p>
        </div>

        <div className="location-boxes">
          <div className="row mb-3">
            <div className="col-2 px-0 flex-center">
              <img src="./images/locationcurrent.png" alt="" />
            </div>
            <div className="col-10">
              <h4 className="location-name">Location 1</h4>
              <p className="text">
                9523 Overlook St. Findlay, Lorem ipsum dolor sit amet,
                consectadipi se
              </p>
              <hr className="line m-0 mt-1" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 px-0 flex-center">
              <img src="./images/locationcurrent.png" alt="" />
            </div>
            <div className="col-10">
              <h4 className="location-name">Location 1</h4>
              <p className="text">
                9523 Overlook St. Findlay, Lorem ipsum dolor sit amet,
                consectadipi se
              </p>
              <hr className="line m-0 mt-1" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 px-0 flex-center">
              <img src="./images/locationcurrent.png" alt="" />
            </div>
            <div className="col-10">
              <h4 className="location-name">Location 1</h4>
              <p className="text">
                9523 Overlook St. Findlay, Lorem ipsum dolor sit amet,
                consectadipi se
              </p>
              <hr className="line m-0 mt-1" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 px-0 flex-center">
              <img src="./images/locationcurrent.png" alt="" />
            </div>
            <div className="col-10">
              <h4 className="location-name">Location 1</h4>
              <p className="text">
                9523 Overlook St. Findlay, Lorem ipsum dolor sit amet,
                consectadipi se
              </p>
              <hr className="line m-0 mt-1" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 px-0 flex-center">
              <img src="./images/locationcurrent.png" alt="" />
            </div>
            <div className="col-10">
              <h4 className="location-name">Location 1</h4>
              <p className="text">
                9523 Overlook St. Findlay, Lorem ipsum dolor sit amet,
                consectadipi se
              </p>
              <hr className="line m-0 mt-1" />
            </div>
          </div>

          <div className="text-center my-3">
            <button className="btn-none view-all " onClick={props.closePopup}>
              Show More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
