import React, { useState } from "react";
import ForgetPWD from "./ForgetPwd";
import JobseekerLogin from "./JobseekerLogin";
import Otp from "./Otp";

const JobseekerLoginPage = () => {

  const [otp, setOtp] = useState(false);
  const [forgetPwd, setForgetPwd] = useState(false);
  const [activeSection, setActiveSection] = useState(1);

  return (
    <>
      <div className="flex-center">
        <div className="login-container">
          <h1 className="login-title">Jobseeker Login </h1>
          <div className="d-flex space-between mt-2 submenu-bar">
            <p
              className={
                activeSection === 1 || activeSection === 2
                  ? "login-active"
                  : "login"
              }
              onClick={() => {
                setActiveSection(1);
              }}
            >
              Login
            </p>
            <p
              className={
                activeSection === 3 || activeSection === 4
                  ? "login-active"
                  : "login"
              }
              onClick={() => {
                setActiveSection(3);
              }}
            >
              OTP
            </p>
          </div>
          <hr className="line" style={{ marginTop: "-4px" }} />

          {activeSection === 1 || activeSection === 2 ? (
            activeSection === 1 ? (
              <JobseekerLogin setActiveSection={setActiveSection} />
            ) : (
              <ForgetPWD setActiveSection={setActiveSection} />
            )
          ) : (
            <Otp />
          )}
        </div>
      </div>
    </>
  );
};

export default JobseekerLoginPage;
