import React from "react";
import { Link } from "react-router-dom";

const JobseekerFooter = () => {
  return (
    <>
      <div className="recruiter-footer-container space-between sticky-top d-flex">
        <div className="row">
          <div
            className="col-sm-6 col-lg-4 col-md-6"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            {/* <div>
            <p style={{color:"#ffffff", fontWeight:"bold", fontSize:"30px"}}>LOGO</p>
          </div> */}
            <div className="w-100 h-100">
              <a href="/">
                <img
                  className="mfooter-social-icons"
                  src="./images/facebook.png"
                  alt=""
                />
              </a>
              <a href="/">
                <img
                  className="mfooter-social-icons"
                  src="./images/instagram.png"
                  alt=""
                />
              </a>
              <a href="/">
                <img
                  className="mfooter-social-icons"
                  src="./images/twitter.png"
                  alt=""
                />
              </a>
              <a href="/">
                <img
                  className="mfooter-social-icons"
                  src="./images/linkdin.png"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-md-6 align-items-end">
            <div>
              <Link to="/" className="footer-links">
                <p className="text-white menu-label mt-1">About Us</p>
              </Link>
            </div>
            <div>
              <Link to="/" className="footer-links">
                <p className="text-white menu-label mt-1">Privacy Policy</p>
              </Link>
            </div>
            <div>
              <Link to="/" className="footer-links">
                <p className="text-white menu-label mt-1">Terms & Conditions</p>
              </Link>
            </div>
            <div>
              <Link to="/" className="footer-links">
                <p className="text-white menu-label mt-1">Help</p>
              </Link>
            </div>
            <div>
              <Link to="/" className="footer-links">
                <p className="text-white menu-label mt-1">Contact Us</p>
              </Link>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-4 mt-md-3 mt-sm-3 flex-center"
            style={{ flexDirection: "column" }}
          >
            {/* <div>
            <p style={{color:"#ffffff", fontWeight:"bold", fontSize:"30px"}}>LOGO</p>
          </div> */}
            <div className="w-100 mt-md-3">
              <a href="/">
                <img
                  className="mfooter-appstore-icons"
                  src="./images/playstore.png"
                  alt=""
                />
              </a>
              <a href="/">
                <img
                  className="mfooter-appstore-icons"
                  src="./images/appstore.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobseekerFooter;
