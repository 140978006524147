import React, { useState } from "react";
import { Link } from "react-router-dom";
import Notification from "../pages/home/Notification";
const Header = () =>{
  const [notification, setNotification] = useState(false);
    return (
    <div className="header-container space-between sticky-top d-flex py-1" style={{minHeight: "64px"}}>
      <div className="flex-center">
          <p style={{color:"#ffffff", marginLeft:"40px", fontWeight:"bold", fontSize:"30px"}}> <img src="./images/logo-atzapp_white.png"/> </p>
      </div>
      <div className="d-flex">
      </div>  
    </div>
  );
}

export default Header;
