import { baseUrl } from "./service";

// export async function postData(data, urlPath) {
//   console.log(baseUrl)
//   console.log(urlPath)

//   try {

//     let strFetchingUrl = baseUrl + urlPath;
//     const res = await fetch(strFetchingUrl, {
//       mode: 'no-cors',
//       method: "POST",
//       headers: {
//         // "Accept": "*/*",
//         // 'Accept': 'application/json',
//         'Accept': '*/*',
//         'Content-Type': 'application/json',
//         // "charset": "utf-8",
//         "Access-Control-Allow-Origin": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
//       },
//       body: JSON.stringify(data),
//     });

//     return await res;

//   } catch (error) {
//     console.log(error)
//   }
// }



export async function postData(data, urlPath) {

  const res = await fetch(baseUrl + urlPath, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Origin: process.env.ORIGIN,
      Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
      host: baseUrl,
      'Accept': '*/*'
      // AcceptEncoding: "gzip, deflate, br"
    },
    body: JSON.stringify(data),
  });

  return await res;
}


export async function postDataAuth(data, urlPath) {

  const res = await fetch(baseUrl + urlPath, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Origin: process.env.ORIGIN,
      Authorization: 'Bearer ' + localStorage.getItem("access token login"),
      host: baseUrl,
      'Accept': '*/*'
      // AcceptEncoding: "gzip, deflate, br"
    },
    body: JSON.stringify(data),
  });

  return await res;
}
export async function getData(urlPath) {
  let accessTokenKey = '';
  accessTokenKey = localStorage.getItem("access token");
  const res = await fetch(baseUrl + urlPath, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      // Origin: 'http://localhost:3000'+"",

      Authorization: accessTokenKey,
    },
  });
  return await res;
}

 

// export async function postData(data, urlPath) {
//   const res = await fetch(siteConfig.BASE_URL + urlPath, {
//     method: 'POST',
//     mode: 'cors',
//     cache: 'no-cache',
//     credentials: 'same-origin',
//     headers: {
//       'Content-Type': 'application/json',
//       Origin: process.env.ORIGIN,
//       authorization:
//         'Bearer ' + localStorage.getItem(siteConfig.ACCESS_TOKEN_KEY),
//     },
//     body: JSON.stringify(data),
//   });

//   return await res;
// }